
import styled from 'styled-components';
import { motion } from 'framer-motion';

export const StepContentWrapper = styled(motion.div)`
  height: 100%;
  padding-top: 56px;
  padding-left: 40px;
  padding-right: 40px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
`;
