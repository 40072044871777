
import styled from "styled-components";
import React from "react";

const ErrorMessage = ({ message }: IProps) => {
    return <SpanMessage>{message}</SpanMessage>
}

export default ErrorMessage;

const SpanMessage = styled.span`
  height: 10px;
  font-size: 12px;
  color: red;
  padding-left: 10px;
`;

interface IProps {
    message: string | undefined
}
