
import styled from 'styled-components';
import { useRef, useContext } from "react";
import { ClientContext } from "../Context/ClientContext";
import Proposal from "../Components/Proposal";
import { useReactToPrint } from "react-to-print";
import { NUVO_BLUE, NUVO_GREY, NUVO_LIGHT_GREY } from '../utils/constants';
import { Navigate } from "react-router-dom";
import RequireUserAuth from '../Components/Auth/RequireUserAuth';
import { format } from 'date-fns';

const ProposalPage = () => {
  const { progress, clientData } = useContext(ClientContext);
  const date = format(new Date(), 'MMddyyyy')
  const proposalRef = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => proposalRef.current,
    documentTitle: `solar_proposal_${clientData.name.replace(/\s/g, '').toLowerCase()}_${date}`,
  });

  return ( progress?.systemTypeComplete 
  ? (
    <RequireUserAuth role="SALES" >
    <div ref={proposalRef}>
      <Proposal />
      <PrintButtion onClick={handlePrint}>Print PDF</PrintButtion>
    </div>
    </RequireUserAuth>
    )
  : <Navigate to="/step3" />
  )
}

export default ProposalPage;

const PrintButtion = styled.button`
  position: fixed;
  cursor: pointer;
  top: 10px;
  right: 10px;
  background-color: ${NUVO_BLUE};
  color: ${NUVO_LIGHT_GREY};
  border: none;
  outline: none;
  height: 50px;
  width: 148px;
  border-radius: 5px;
  &:active {
    background-color: ${NUVO_GREY};
    box-shadow: 0 2px #999;
    transform: translateY(2px);
  }
  @media print {
      visibility: hidden;
    }
`;



