
import { useState } from 'react';
import styled from 'styled-components';

interface IProps {
    name: string,
    value: any,
    onChange: any
  }

const FormattedNumberInput = ({ name, value, onChange }: IProps) => {
  const [isEditing, setIsEditing] = useState(false);
  
  const toggleEditing = () => {
    setIsEditing(!isEditing);
  }

  const formatNumberAsText = (number: number) => {
    return new Intl.NumberFormat('en-ZA', {
        style: 'currency',
        currency: 'ZAR',
        minimumFractionDigits: 0
      }).format(number);
  }
  

  return (
      <div>
        <label htmlFor={name}>Bill in Rands</label>
        {isEditing ? (
          <FormInput
            type="number"
            name={name}
            value={value}
            onChange={onChange}
            onBlur={toggleEditing}
          />
        ) : (
          <FormInput
            type="text"
            name={name}
            value={formatNumberAsText(value)}
            onFocus={toggleEditing}
            
          />
        )}
      </div>
    );
}

export default FormattedNumberInput;


export const FormInput = styled.input`
  margin-top: 4px;
  padding: 0;
  width: 100%;
  height: 32px;
  border: none;
  outline: none;
  border-bottom: 1px solid #d5d9dc;
`;
