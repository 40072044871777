
import styled from "styled-components";
import energyIcon from "../../images/energyIcon2.svg";
import backupIcon from "../../images/energyIcon.svg";


interface IProps {
  backupChecked: boolean,
  independantChecked: boolean,
  handleChange: (e: any) => void
}

const SystemCheckBox = ({ backupChecked, independantChecked, handleChange}: IProps) => {
  return (
    <>
      <Box>
        <CheckBox name="backup" type="checkbox" checked={backupChecked} onChange={handleChange} />
        <SystemCompoents src={backupIcon} alt="Backup System" />
      <Text>Backup</Text>
      <Text>Systems</Text>
      </Box>
      <Box>
        <CheckBox name="independant" type="checkbox"checked={independantChecked} onChange={handleChange} />
        <SystemCompoents src={energyIcon} alt="Independant System" />
        <Text>Independant System</Text>
      </Box>
    </>
  )
}

export default SystemCheckBox;

const Box = styled.div`
  border: 1.5px solid #d5d9dc;
  border-radius: 4px;
  padding: 16px;
  width: 136px;
  height: 148px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
`;

const Text = styled.div`
  font-family: "Montserrat";
  font-size: 0.875rem;
`;

const SystemCompoents = styled.img`
height : 80px;
width : 150px;
`;

const CheckBox = styled.input`
 width: 25px;
 height: 25px;
`;



