
import useLogin from "./useLogin";
import EmailPasswordForm from "../../Components/Form/EmailPasswordForm";

const Login = () => {
    const { handleChange, handleSubmit, values, errors, message, loading} = useLogin();

    return (
      <>
        <EmailPasswordForm
          title="Login"
          handleChange={handleChange}
          handleSubmit={handleSubmit}
          loading={loading}
          values={values}
          errors={errors}
          message={message}
          includePassword={true}
         />
      </>
    )
}

export default Login;
