
import { useState, createContext, ReactNode, PropsWithChildren, useEffect } from 'react';
import { get, set, del } from 'idb-keyval';
import { Roles } from '../utils/constants';

interface IUser {
    user: {
      uid  : string,
      email: string,
      company_id: string,
      role: string
    },
    authed?: {
      NUHOME_ADMIN: boolean,
      INSTALLER_ADMIN: boolean,
      TECHNICIAN: boolean,
      CUSTOMER: boolean,
      SALES: boolean
    },
    loading?: boolean,
    userLogin?: (user: IUser['user']) => Promise<string>
    userSignOut?: () => Promise<void>
};

export const UserContext = createContext<IUser>({
    user: {
        uid: '',
        email: '',
        company_id: '', 
        role: ''
    }
});

export const LOCAL_KEY = 'pwaAuth';

export const getUserFromLocalStorage = async () => {
      const userInfo = await get(LOCAL_KEY);
      return userInfo;
    };

export const addUserToLocalStorage = async (user: IUser['user']) => {
    const addUser = await set(LOCAL_KEY, user );
    return addUser;
  };

const UserContextProvider = (props: ReactNode  | PropsWithChildren<any>) => {
    const [authed, setAuthed] = useState({
        NUHOME_ADMIN: false,
        INSTALLER_ADMIN: false,
        TECHNICIAN: false,
        CUSTOMER: false,
        SALES: false,
      });

    const [loading, setLoading] = useState(true);
    const [user, setUser] = useState({
        uid: '',
        email: '',
        company_id: '',
        role:'',
    });

    const setUserAndAuthState = (userInfo: IUser['user']) => {
      setUser({...userInfo});
      if (userInfo.role === Roles.NUHOME) setAuthed({...authed, NUHOME_ADMIN: true}); 
      if (userInfo.role=== Roles.INSTALLER) setAuthed({...authed, INSTALLER_ADMIN: true}); 
      if (userInfo.role === Roles.TECHNICIAN) setAuthed({...authed, TECHNICIAN: true}); 
      if (userInfo.role === Roles.CUSTOMER) setAuthed({...authed, CUSTOMER: true});
      if (userInfo.role === Roles.SALES) setAuthed({...authed,  SALES: true});
      setLoading(false);
      }; 

    // CHECK IF USER INFO IS STORED IN LOCAL STORAGE
    useEffect(() => {
      const checkUserAuthAndRole = async () => {
        const userInfo  = await getUserFromLocalStorage();
        const userInfoExists = userInfo !== undefined;

        if (userInfoExists) {
          setUserAndAuthState(userInfo);
          }
        if (!userInfoExists) setLoading(false);        
        };

      checkUserAuthAndRole().catch(error => console.log("USER CONTEXT ERROR:", error));
        
    }, []);

    useEffect(() => {
        console.log(user);
        console.log(authed);
    }, [user, authed]);

    const userLogin = async (user: IUser['user']) => {
      return new Promise<string>( async (resolve) => {
        setUserAndAuthState(user);
        await set(LOCAL_KEY, user);
        resolve(user.role);
        });
      };

    const userSignOut = async () => {
        return new Promise<void>( async (resolve) => {
            setAuthed({NUHOME_ADMIN: false, INSTALLER_ADMIN: false, TECHNICIAN: false, CUSTOMER: false, SALES: false});
            await del(LOCAL_KEY);
            resolve();
          });
      };

    return (
        <UserContext.Provider value={{user, authed, loading, userLogin, userSignOut}}>
            {props.children}
        </UserContext.Provider>
    );
};

export default UserContextProvider;

//export const UserAuth = () => {
    //return UserContext
//}

