
import { FormEvent, useState, useContext } from "react";
import { ClientContext } from "../Context/ClientContext";
import { useNavigate } from "react-router-dom";

interface IErrors {
  systemType?: string
}

const useSystemType = () => {
  const { addSystemType, addDataToFirestore } = useContext(ClientContext);
  const [backupChecked, setBackupChecked] = useState(false);
  const [independantChecked, setIndependantChecked] = useState(false);
  const [errors, setErrors] = useState<IErrors>({});
  const navigate = useNavigate();

  const handleChange = (e : any) => {
    const { name } = e.currentTarget;
    if (name === 'backup') {
      setBackupChecked(!backupChecked);
      if (independantChecked) setIndependantChecked(false);
    }
    if (name === 'independant') {
      setIndependantChecked(!independantChecked);
      if (backupChecked) setBackupChecked(false);
    }
  }

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setErrors({});
    const { errors } = validateSystemType(backupChecked, independantChecked);
    if (Object.keys(errors).length > 0) {
      setErrors(errors);
    } else {
      const systemType = backupChecked ? 'backup' : 'independant';
      if (addSystemType !== undefined) {
        console.log(systemType);
        await addSystemType(systemType);
        if (addDataToFirestore !== undefined) {
          console.log("SAVING....")
          await addDataToFirestore(systemType);
          navigate("/proposal");
        }; 
      }
    }
  }

  return { backupChecked, independantChecked, errors, handleChange, handleSubmit };
}

export default useSystemType;

const validateSystemType = (backupChecked: boolean, independantChecked: boolean) => {
  const errors: IErrors = {};
  if (!backupChecked && !independantChecked) errors.systemType = 'Please select a system type';
  return { errors };
}
