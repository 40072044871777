
import styled from "styled-components";

const Privacy = () => {
  return (
   <PolicyWrapper>
    <Header>PRIVACY POLICY</Header>
    <Text>We use your personal data to provide and solar products and services. By using our service, You agree to the collection and use of information in accordance with this Privacy Policy.</Text>
    <Section>Types of data collected </Section>
    <Text>While using our service, We may ask you to provide us with certain personally identifiable information that can be used to contact or identify uderlying property owners. Personally identifiable information may include:</Text>
    <Text>- First and last name</Text>
    <Text>- Email Address</Text>
    <Text>- Property Information, including physical address</Text>
    <Text>- Phone Number</Text>
    <SectionSmall>Usage Data</SectionSmall>
    <Text>Usage Data is collected automatically when using the Service.
      Usage Data may include information such as Your Device's Internet Protocol address (e.g. IP address), browser type, browser version, the pages of our Service that You visit, the time and date of Your visit, the time spent on those pages, unique device identifiers and other diagnostic data.
      When You access the Service by or through a mobile device, We may collect certain information automatically, including, but not limited to, the type of mobile device You use, Your mobile device unique ID, the IP address of Your mobile device, Your mobile operating system, the type of mobile Internet browser You use, unique device identifiers and other diagnostic data.
      We may also collect information that Your browser sends whenever You visit our Service or when You access the Service by or through a mobile device.
    </Text>
    <Section>Use of Your Personal Data</Section>
    <Text>The Company may use Personal Data for the following purposes:<br/>
      - To provice and maintain our service: including to monitor the usage of our Service.<br/>
      - To manage Your Account: to manage Your registration as a user of the Service. The Personal Data You provide can give You access to different functionalities of the Service that are available to You as a registered user.<br/>
      - For the performance of a contract: the development, compliance and undertaking of the purchase contract for the products, items or services You have purchased or of any other contract with Us through the Service.<br/>
      - To contact You: To contact You by email, telephone calls, SMS, or other equivalent forms of electronic communication, such as a mobile application's push notifications regarding updates or informative communications related to the functionalities, products or contracted services, including the security updates, when necessary or reasonable for their implementation.<br/>
      - To provide You with news, special offers and general information about other goods, services and events which we offer that are similar to those that you have already purchased or enquired about unless You have opted not to receive such information.<br/>
      - To manage Your requests: To attend and manage Your requests to Us.<br/>
      - For business transfers: We may use Your information to evaluate or conduct a merger, divestiture, restructuring, reorganization, dissolution, or other sale or transfer of some or all of Our assets, whether as a going concern or as part of bankruptcy, liquidation, or similar proceeding, in which Personal Data held by Us about our Service users is among the assets transferred.<br/>
      - For other purposes: We may use Your information for other purposes, such as data analysis, identifying usage trends, determining the effectiveness of our promotional campaigns and to evaluate and improve our Service, products, services, marketing and your experience.<br/>
    </Text>
    <Section>Retention of Personal Data</Section>
    <Text>The Company will retain personal data only for as long as is necessary for the purposes set out in this Privacy Policy. We will retain and use Your Personal Data to the extent necessary to comply with our legal obligations (for example, if we are required to retain your data to comply with applicable laws), resolve disputes, and enforce our legal agreements and policies.</Text>
    <Text>If you wish to find out what what personal information we hold or wish us to remove and opt out of our sercives please contact us at <b>info@nuhome.app</b></Text>
    <Section>Usage, Performance and Miscellaneous</Section>
    <Text>We may use third-party Service Providers to provide better improvement of our Service.<br/>
      Google Places: <br/>
      Google Places is a service that returns information about places using HTTP requests. It is operated by Google.<br/>
      The information gathered by Google Places is held in accordance with the Privacy Policy of Google: <b>https://www.google.com/intl/en/policies/privacy/</b><br/>
    </Text>
    <Text>If you have any questions about this Privacy Policy, You can contact us: info@nuhome.app</Text>

   </PolicyWrapper>

  )
};

export default Privacy;

const PolicyWrapper = styled.div`
  width: 100%;
  margin-top: 40px;
  margin-bottom: 20px;
  font-family: "Montserrat";
`;

const Header = styled.div`
  font-size: 1rem;
  font-weight: 700;
  margin-bottom: 10px;
`;

const Section = styled.div`
  font-size: 0.85rem;
  font-weight: 700;
`;

const SectionSmall = styled.div`
  font-size: 0.7rem;
  font-weight: 700;
`;

const Text = styled.div`
  font-size: 0.65rem;
  margin-bottom: 10px;
`;


