// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const TEST_CONFIG = {
  apiKey: "AIzaSyBHU29dBiraaYC0Lgq267icKc8LG7aqPjw",
  authDomain: "proposal-machine.firebaseapp.com",
  projectId: "proposal-machine",
  storageBucket: "proposal-machine.appspot.com",
  messagingSenderId: "582322489247",
  appId: "1:582322489247:web:c6543714bdcc462920dfaa"
};

 
const REACT_APP_FIRBASE_APIKEY_PROD="AIzaSyCdG_sHcXGrci2smn1ubXi4zYNIepJPtQs"
const REACT_APP_FIREBASE_AUTH_DOMAIN_PROD="nuhome-graphql-server.firebaseapp.com"
const REACT_APP_FIREBASE_DATABASE_URL_PROD="https://nuhome-graphql-server.firebaseio.com"
const REACT_APP_FIREBASE_PROJECT_ID_PROD="nuhome-graphql-server"
const REACT_APP_FIREBASE_STORAGE_BUCUKET_PROD="nuhome-graphql-server.appspot.com"
const REACT_APP_FIREBAE_MESSAGING_SENDER_ID_PROD="621749496708"
const REACT_APP_FIREBASE_APP_ID_PROD="1:621749496708:web:aa8d2c4d048020ab79cbd1"
const REACT_APP_FIREBASE_MEASUREMENT_ID_PROD="G-V0785V9ZN5"


const PROD_CONFIG = {
  apiKey: REACT_APP_FIRBASE_APIKEY_PROD,
  authDomain: REACT_APP_FIREBASE_AUTH_DOMAIN_PROD,
  databaseURL: REACT_APP_FIREBASE_DATABASE_URL_PROD,
  projectId: REACT_APP_FIREBASE_PROJECT_ID_PROD,
  storageBucket: REACT_APP_FIREBASE_STORAGE_BUCUKET_PROD,
  messagingSenderId: REACT_APP_FIREBAE_MESSAGING_SENDER_ID_PROD,
  appId: REACT_APP_FIREBASE_APP_ID_PROD,
  measurementId: REACT_APP_FIREBASE_MEASUREMENT_ID_PROD
};

const DEV_CONFIG = {
  apiKey: process.env.REACT_APP_FIRBASE_APIKEY_DEV,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN_DEV,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL_DEV,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID_DEV,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCUKET_DEV,
  messagingSenderId: process.env.REACT_APP_FIREBAE_MESSAGING_SENDER_ID_DEV,
  appId: process.env.REACT_APP_FIREBASE_APP_ID_DEV,
}

console.log(process.env.REACT_APP_NODE_ENV);
//const firebaseConfig = process.env.REACT_APP_NODE_ENV === 'production' ? PROD_CONFIG : DEV_CONFIG;
const firebaseConfig = PROD_CONFIG;
// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const firestore = getFirestore(app);



