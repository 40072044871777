
import { Children, FormEvent } from "react";
import styled from "styled-components";
import ButtonSpinner from "../ButtonSpinner";
import ErrorMessage from "../ErrorMessage";
import { LIGHT_BLUE, FONT_HEADER, FONT_TITLE, NUVO_GREY } from "../../utils/constants";

export type EmailPasswordType = {
    email: string,
    password: string
}

export type EmailPasswordErrorType = {
    email?: string,
    password?: string
}

interface IProps {
    title: string,
    loading: boolean,
    values: EmailPasswordType,
    errors: EmailPasswordErrorType,
    message: string,
    includePassword?: boolean,
    handleChange: ( e: FormEvent<HTMLInputElement>) => void,
    handleSubmit: (e:FormEvent<HTMLFormElement>) => void
}

 const EmailPasswordForm = ({title, handleChange, handleSubmit, values, errors, message, loading, includePassword = true}: IProps) => {

    const handleSubmitMessage = (message: string) => {
        switch(message) {
            case 'firebaseFail': 
                return <Message>Invalid username or password</Message>
            case 'fail':
                return <Message>Sorry, something went wrong, please contact the nuhome team</Message>
            default: 
            return <Message>{message}</Message>
        }
    }

    const handleButtonState = () => {
        return <SubmitButton> {loading ? <ButtonSpinner /> : title} </SubmitButton>
    }

    const handleRender = () => {
        return (
            <FormContainer noValidate onSubmit={handleSubmit}>
                <LabelContainer>
                    <Label htmlFor="email" name="Email">Email</Label>
                </LabelContainer>
                <Input
                    placeholder="name@domain.com"
                    name="email"
                    type="email"
                    value={values.email}
                    onChange={handleChange}
                    autoComplete="current-email"
                />
                <ErrorMessage message={errors.email} />
                { includePassword
                    ? <>
                        <LabelContainer>
                            <Label htmlFor="password" name="Password">Password</Label>
                        </LabelContainer>
                        <Input
                            placeholder="password"
                            name="password"
                            type="password"
                            value={values.password}
                            onChange={handleChange}
                            autoComplete="current-password"
                        />
                        <ErrorMessage message={errors.password} />
                    </>
                    : null
                }
                
                { handleSubmitMessage(message)}
                {handleButtonState()}
            </FormContainer>
        )
}
    return (
        <Wrapper>
          <Content>
            <HeaderBox>
                <HeaderTextContainer>
                    <Header>{title}</Header>
                    <Text>Proposal Builder</Text>
                </HeaderTextContainer>
            </HeaderBox>
            <MobileBox>
                {handleRender()}
            </MobileBox>
          </Content>
        </Wrapper>
    )
}

export default EmailPasswordForm;

interface MessageProps {
    success?: boolean
}

const Message = styled.span<MessageProps>`
  height: 10px;
  font-size: 10px;
  color: ${props => props.success ? 'green' : 'red'};
  padding-left: 10px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
`;

const SubmitButton = styled.button`
  background-color: ${LIGHT_BLUE};
  color: ${FONT_HEADER};
  width: 85%;
  height: 50px;
  border: none;
  border-radius: 20px;
  letter-spacing: 1.5px;
  cursor: pointer;
  margin-top: 2rem;
  margin-bottom: 20px;
  
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${NUVO_GREY};
  justify-content: center;
  align-items: center;
  height: 100vh;
`;

const Content = styled.div`
  font-family: "Trebuchet MS", sans-serif;
  width: 100vw;
  max-width: 400px;
  justify-content: center;
`;

const HeaderBox = styled.div`
  height: 170px;
  width: 100%;
  border-radius: 20px 20px 0 0;
  background-color: ${LIGHT_BLUE} ;
`;

const HeaderTextContainer = styled.div`
  width: 85%;
  margin: 0 auto;
  padding-top: 80px;
`;

const Header = styled.p`
  margin: 0;
  font-weight: bold;
  color: ${FONT_HEADER};
  font-size: 24px;
`;

const Text = styled.p`
  color: ${FONT_HEADER};
  font-size: 14px;
`;

const MobileBox = styled.div`
  background-color: white;
  padding-top: 20px;
  width: 100%;
  align-items: center;
  border-radius: 0 0 20px 20px;
  padding-bottom: 20px;
`;

/* const LoginContainer = styled.form`
  width: 100%;
  height: 80%;
  overflow: hidden;
  display: flex;
  align-items: center;
  align-content: center;
  flex-direction: column;
`; */
const FormContainer = styled.form`
  width: 100%;
  height: 80%;
  overflow: hidden;
  display: flex;
  align-items: center;
  align-content: center;
  flex-direction: column;
`;

const LabelContainer = styled.div`
    width: 100%;
  margin-bottom: 5px;
`;
const Label = styled.label<LabelProps>`
  height: 10px;
  padding-left: 8%;
  font-size: 14px;
  font-weight: bold;
  color: ${FONT_TITLE};
`;

interface LabelProps {
    name: string
}

const Input = styled.input`
  color: darkgray;
  width:  80% ;
  height: 50px;
  outline: none;
  font-size: 18px;
  padding-left: 15px;
  border: 1px solid lightgray;
  border-radius: 20px;
  
  &::placeholder {
    color: rgb(200, 200, 200, 1);
  }
  
  &:focus {
    outline: none;
    border-color: ${LIGHT_BLUE};
    box-shadow: 0 0 2.5px ${LIGHT_BLUE};
    transition: all 300ms ease-in;
  }
`;

