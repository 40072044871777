
import { FormEvent, useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { ClientContext } from "../Context/ClientContext";

interface IValues { 
  name: string,
    phone: string,
    email: string,
    address?: {
      label: string,
      value: string
    }
    customAddress?: string
}

interface IErrors { 
    name?: string,
    phone?: string,
    email?: string,
    address?: string
}


const useContactDetails = (isCustomAddress: boolean) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState<IErrors>({});
  const [values, setValues] = useState({
    name: '',
    phone: '',
    email: '',
    address: {
      label: '',
      value: ''
    },
    customAddress: ''
  });
  const { addClientDetails } = useContext(ClientContext);

  const handleChange = (e: FormEvent<HTMLInputElement>) => {
    
    const {name, value} = e.currentTarget;
    console.log("change name", name);
    console.log("change value", value);
    setValues({
      ...values,
      [name]: value
    });
  };


  const handleAddressChange = (value: any) => {
    console.log("changing", value );
    setValues({
      ...values,
      address: value
    });
  };


  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setErrors({});
    const { errors } = validateContactDetails(values);
    if (Object.keys(errors).length > 0) {
      setErrors(errors);
    } else {
      console.log(values);
      setLoading(true);
      const { name, phone, email, address, customAddress } = values;
      if (addClientDetails === undefined) { console.log("Adding Client Details Failed")}
      if (addClientDetails !== undefined) {
        console.log("HIT")
        addClientDetails({
          name: name, 
          phone: phone, 
          email: email, 
          address: isCustomAddress ? customAddress : address.label });
          navigate('/step2')
      }
      // store information in firebase collection
    }

  };

  useEffect(() => {

  });

  return { handleChange, handleAddressChange, handleSubmit, values, errors, loading };
};

export default useContactDetails;

const validateContactDetails = (values: IValues) => {
  const errors: IErrors = {};
    const regEx = /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i;

    if (!values.name) errors.name = "Full name is required";
    if (!values.phone) errors.phone = "Phone Number is required";
    if (!values.email) errors.email = "Email address is required";
    if (!regEx.test(values.email))  errors.email = "Email address is required";
    //if (!values.address.label || !values.customAdress) errors.address = "Property address is required";

    return { errors };

}
