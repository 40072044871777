
export const NUVO_BLUE = "#00AFEF";
export const NUVO_GREY = "#60686E";
export const NUVO_LIGHT_GREY = "#F4F4F4";
export const FONT_HEADER = '#FFFFFF';
export const FONT_TITLE = '#000000';
export const LIGHT_BLUE = '#00bfff';
export const INSTALLER_YELLOW = '#FED000';
export const NUHOME = 'NUHOME_ADMIN';
export const INSTALLER = 'INSTALLER_ADMIN';
export const TECHNICIAN = 'TECHNICIAN';
export const CUSTOMER = 'CUSTOMER';
export const SALES = 'SALES';

export const Roles = {
  CUSTOMER,
  NUHOME,
  TECHNICIAN,
  INSTALLER,
  SALES
}


