
import { FormEvent, ChangeEvent, useContext, useState } from 'react';
import { ClientContext } from '../Context/ClientContext';
import { useNavigate } from 'react-router-dom';
import { FormSelect } from '../Components/Form/FormElements';

interface IValues {
    bill: number | null
    houseSize: number | null
    configuration: number | null
    roofType: string | null
    province: string | null
    municipality: string | null,
  }

interface IError {
    bill?: string,
    houseSize?: string
    configuration?: string
    roofType?: string
    province?: string
    municipality?: string
  }

const calculateNuhomeSystem = (size: number):string => {
    if (size > 0 && size <= 13 ) return 'nuhome10';
    if (size > 13 && size <= 18) return 'nuhome15';
    if (size > 18 && size <= 23) return 'nuhome20';
    if (size > 23 && size <= 33) return 'nuhome30';
    if (size > 33 && size <= 48) return 'nuhome45';
    if (size > 38 && size <= 63) return 'nuhome60';
    if (size > 63 && size <= 90) return 'nuhome75';
    else return 'nuhomeBusiness';
};

const useSystemSizeCalculator = () => {
  
  const {addSystemSize} = useContext(ClientContext);
  const navigate = useNavigate();
  const [errors, setErrors] = useState<IError>({});
  const [values, setValues] = useState<IValues>({
    bill: null,
    houseSize: 0,
    configuration: 0,
    roofType: '',
    province: '',
    municipality: '',
  });

  const handleMunicipalityListRender = () => {
    switch (values.province) {
        case 'gauteng':
          return (
            <FormSelect name="municipality" onChange={handleOptionsChange}>
              <option selected disabled>Choose municipality</option>
              <option value={"eskom"}>Eskom Direct</option>
              <option value={"ekurhuleni"}>City of Ekurhuleni Metropolitan</option>
              <option value={"johannesburg"}>City of Johannesburg Metropolitan</option>
              <option value={"tshwane"}>City of Tshwane Metropolitan</option>
              <option value={"sedibeng"}>Sedibeng District Municipality</option>
              <option value={"westrand"}>West Rand District Municipality</option>
            </FormSelect>
          );
        case 'easterncape':
          return (
            <FormSelect name="municipality" onChange={handleOptionsChange}>
              <option selected disabled>Choose municipality</option>
              <option value={"eskom"}>Eskom Direct</option>
              <option value={"buffalocity"}>Buffalo City Metropolitan</option>
              <option value={"nelsonmandela"}>Nelson Mandela Bay Metropolitan</option>
              <option value={"alfrednzo"}>Alfred Nzo District Municipality</option>
              <option value={"amathole"}>Amathole District Municipality</option>
              <option value={"chrishani"}>Chris Hani District Municipality</option>
              <option value={"joegqabi"}>Joe Gqabi District Municipality</option>
              <option value={"ortambo"}>OR Tambo District Municipality</option>
              <option value={"sarahbaartman"}>Sarah Baartman District Municipality</option>
            </FormSelect>
          );
        case 'freestate':
          return (
            <FormSelect name="municipality" onChange={handleOptionsChange}>
              <option selected disabled>Choose municipality</option>
              <option value={"eskom"}>Eskom Direct</option>
              <option value={"mangaung"}>Mangaung Metropolitan Metropolitan</option>
              <option value={"feziledabi"}>Fezile Dabi District Municipality</option>
              <option value={"lejweleputswa"}>Lejweleputswa District Municipality</option>
              <option value={"thabomofutsanyana"}>Thabo Mofutsanyana District Municipality</option>
              <option value={"xhariep"}>Xhariep District Municipality</option>
            </FormSelect>
          );
        case 'kzn': 
          return (
            <FormSelect name="municipality" onChange={handleOptionsChange}>
              <option selected disabled>Choose municipality</option>
              <option value={"eskom"}>Eskom Direct</option>
              <option value={"ethekwini"}>eThekwini Metropolitan</option>
              <option value={"amajuba"}>Amajuba District Municipality</option>
              <option value={"harrygwala"}>Harry Gwala District Municipality</option>
              <option value={"ilembe"}>iLembe District Municipality</option>
              <option value={"kingcetshwayo"}>King Cetshwayo District Municipality</option>
              <option value={"ugu"}>Ugu District Municipality</option>
              <option value={"umgungundlovu"}>uMgungundlovu District Municipality</option>
              <option value={"umkhanyakude"}>uMkhanyakude District Municipality</option>
              <option value={"umzinyathi"}>uMzinyathi District Municipality</option>
              <option value={"uthukela"}>uThukela District Municipality</option>
              <option value={"zululand"}>Zululand District Municipality</option>
            </FormSelect>
          );
        case 'limpopo':
          return (
            <FormSelect name="municipality" onChange={handleOptionsChange}>
              <option selected disabled>Choose municipality</option>
              <option value={"eskom"}>Eskom Direct</option>
              <option value={"capricorn"}>Capricorn District Municipality</option>
              <option value={"mopani"}>Mopani District Municipality</option>
              <option value={"sekhukhune"}>Sekhukhune District Municipality</option>
              <option value={"vhembe"}>Vhembe District Municipality</option>
              <option value={"waterberg"}>Waterberg District Municipality</option>
            </FormSelect>
          );
        case 'mpumalanga':
          return (
            <FormSelect name="municipality" onChange={handleOptionsChange}>
              <option selected disabled>Choose municipality</option>
              <option value={"eskom"}>Eskom Direct</option>
              <option value={"ehlanzeni"}>Ehlanzeni District Municipality</option>
              <option value={"gertsibande"}>Gert Sibande District Municipality</option>
              <option value={"nkangala"}>Nkangala District Municipality</option>
            </FormSelect>
          );
        case 'northerncape':
          return (
            <FormSelect name="municipality" onChange={handleOptionsChange}>
              <option selected disabled>Choose municipality</option>
              <option value={"eskom"}>Eskom Direct</option>
              <option value={"francesbaard"}>Frances Baard District Municipality</option>
              <option value={"johntaologaetsewe"}>John Taolo Gaetsewe District Municipality</option>
              <option value={"namakwa"}>Namakwa District Municipality</option>
              <option value={"pixleykaseme"}>Pixley Ka Seme District Municipality</option>
              <option value={"zfmgcawu"}>ZF Mgcawu District Municipality</option>
            </FormSelect>
          );
        case 'northwest':
          return (
            <FormSelect name="municipality" onChange={handleOptionsChange}>
              <option selected disabled>Choose municipality</option>
              <option value={"eskom"}>Eskom Direct</option>
              <option value={"bojanalaplatinum"}>Bojanala Platinum District Municipality</option>
              <option value={"drkennethkaunda"}>Dr Kenneth Kaunda District Municipality</option>
              <option value={"drruthsegomotsimompati"}>Dr Ruth Segomotsi Mompati District Municipality</option>
              <option value={"ngakamodirimolema"}>Ngaka Modiri Molema District Municipality</option>
            </FormSelect>
          );
        case 'westerncape':
          return (
            <FormSelect name="municipality" onChange={handleOptionsChange}>
              <option selected disabled>Choose municipality</option>
              <option value={"eskom"}>Eskom Direct</option>
              <option value={"capetown"}>City of Cape Town Metropolitan</option>
              <option value={"capewinelands"}>Cape Winelands District Municipality</option>
              <option value={"gardenroute"}> Garden Route District Municipality</option>
              <option value={"overberg"}>Overberg District Municipality</option>
              <option value={"westcoast"}>West Coast District Municipality</option>
            </FormSelect>
          );
        default: 
        return (
            <FormSelect name="municipality" onChange={handleOptionsChange}>
              <option selected disabled>Choose province</option>
            </FormSelect>
          
        )
      }
  };

  const handleChange = (e: FormEvent<HTMLInputElement>) => {
    const {name, value} = e.currentTarget;
    setValues({
        ...values,
        [name]: value
      });
  };

  const handleOptionsChange = (e: ChangeEvent<HTMLSelectElement>) => {
    const {name, value} = e.currentTarget;
    console.log(value);
    setValues({
      ...values,
      [name]: value
    })
  }

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setErrors({});
    const { errors } = validateSystemSizeCalculator(values);
    if (Object.keys(errors).length > 0) {
      setErrors(errors);
    } else {
      const rate = 2.50;
      const kwhUsedMonth = values.bill !== null ? values.bill / rate : 0;
      const percentDayTimeUse = 1;
      const kwhUsedDayTime = kwhUsedMonth * percentDayTimeUse;
      const daysPerMonth = 30;
      const kwhUsagePerDay = Math.ceil(kwhUsedDayTime / daysPerMonth);
      const dayTimeWindow = 6;
      const kwhDayTimePerHour = kwhUsagePerDay / dayTimeWindow;
      const dayTimeLoadBuffer = 0.2;
      const kWpSolarSystem = Math.ceil((kwhDayTimePerHour) + (kwhDayTimePerHour * dayTimeLoadBuffer));
      console.log("Solor System is",kWpSolarSystem);
      if (addSystemSize !== undefined) {
        const storeSystemInfo = await addSystemSize({
          kwhPeak: kWpSolarSystem,
          systemSize: kwhUsagePerDay,
          nuhomeSystem: calculateNuhomeSystem(kwhUsagePerDay),
          ...values
        });

        if (storeSystemInfo) navigate('/step3');
      }
    }

  }

  return {values, errors, handleChange, handleOptionsChange, handleSubmit, handleMunicipalityListRender}
}

export default useSystemSizeCalculator;

const validateSystemSizeCalculator = (values: IValues) => {
  const errors: IError = {};
  if (!values.bill) errors.bill = "Bill value is required";
  if (values.bill === 0) errors.bill = "Bill value needs to be greater than 0";
  if (values.houseSize === 0) errors.houseSize = "Please select number of bedrooms";
  if (values.configuration === 0) errors.configuration = "Please select if home is single or three phase";
  if (!values.roofType) errors.roofType = "Please select a roof type";
  if (!values.municipality) errors.municipality = "Please select a municipality";

  return {errors};
}
