
import { Navigate } from "react-router-dom";
import { useState, useEffect, useContext, FC, ReactNode } from "react";
import { UserContext, getUserFromLocalStorage } from "../../Context/UserContext";
import { NUHOME, INSTALLER, TECHNICIAN, CUSTOMER, SALES } from "../../utils/constants";

interface IProps {
    children: any,
    role: typeof NUHOME | typeof INSTALLER | typeof TECHNICIAN | typeof CUSTOMER | typeof SALES
  }

const RequireUserAuth = ({ role, children }: IProps) => {
     // check UserContext for Auth State and check if user info is in UserContext.
     const { authed,  loading } = useContext(UserContext);

     if (loading) {
        return <div>Fetching User Profile...</div>
      }

     if (authed) {
       return authed[`${role}`] 
       ? (children)
       : <Navigate to="/login" />
      }

      return <div>Something went wrong...</div>
};
                    
    export default RequireUserAuth;
