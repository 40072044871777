
import styled from 'styled-components';
import logo from '../images/logo_white.png';
import { useContext } from 'react';
import { UserContext } from '../Context/UserContext';

const ProgressBar = () => {
  const { userSignOut } = useContext(UserContext);

  return (
    <TopBannerWrapper>
      <ContentWrapper>
        <Logo src={logo} alt="nuhome_logo"/>
      </ContentWrapper>
      <SignOut onClick={userSignOut}>Sign Out</SignOut>
    </TopBannerWrapper>
  )
}

export default ProgressBar;


const TopBannerWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  height: 160px;
  background-color: #35a0ee;
  position: relative;
`;

const ContentWrapper = styled.div`
  margin: 20px;
`;

const SignOut = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  margin-right: 20px;
  padding: 10px;
  color: #fff;
  background-color: #4682b4;
  border-radius: 10px 10px 0 0;
  cursor: pointer;
  &:hover {
      color: #eee;
      padding: 11px;
    }
`;

const Logo = styled.img`

`;
