
import styled from "styled-components";
import { useEffect } from "react";
import Privacy from "../Components/Privacy";
import { motion } from "framer-motion"

interface ModalProps {
    show: boolean
    toggleShow: () => void; 
  }

const Modal = ({ show, toggleShow }: ModalProps) => {

  return (
    <>
      <ModalWrapper show={show} >
        <Section>
          <ModelContentWrapper>
            <Privacy />
          </ModelContentWrapper>
          <CloseButton onClick={toggleShow}>Close</CloseButton>
        </Section>
      </ModalWrapper>
    </>
  )
}

export default Modal;


interface IProps {
    show?: boolean;
  }

const ModalWrapper = styled(motion.div)<IProps>`
  position: fixed;
  top: 0;
  left: 0;
  width:100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  display: ${props => props.show ? 'block' : 'none'};
  z-index: 1000;
`;

const Section = styled.section`
  position:fixed; 
  overflow-y: scroll;
  border-radius: 10px;
  background: white;
  width: 80%;
  height: 500px;
  top:50%;
  left:50%;
  opacity: 0.9;
  transform: translate(-50%,-50%);
`;

const ModelContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 90%;
  margin: 0 auto;
`;

const CloseButton = styled.button`
  position: absolute;
  top: 0;
  right: 10px;
  background-color: #ff5252;
  color: white;
  width: 50px;
  height: 30px;
  border: none;
  border-radius: 3px;
  cursor: pointer;
  margin-top: 10px;
  margin-bottom: 20px;
`;
