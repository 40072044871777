

import { StepContentWrapper } from '../Components/StepContentWrapper';
import styled from 'styled-components';
import { Form, FormRow, FormRow2Col, Form2Col, FormLabel, FormInput, FormError, FormSelect, CheckBoxRow } from '../Components/Form/FormElements';
import { InlineIcon } from '@iconify/react';
import Modal from '../Components/Modal';
import { useState , useContext } from 'react';
import {BlueButton} from '../Components/Buttons';
import useSystemType from '../Hooks/useSystemType';
import SystemCheckBox from '../Components/Form/SystemCheckBox';
import ProgressBar from '../Components/ProgressBar';
import RequireUserAuth from '../Components/Auth/RequireUserAuth';
import { Navigate } from "react-router-dom";
import { ClientContext } from '../Context/ClientContext';

const SystemType= () => {
  const [showModal, setShowModal] = useState(false);
  const { progress } = useContext(ClientContext);

  const {backupChecked, independantChecked, errors, handleChange, handleSubmit} = useSystemType();

  const handleModal = () => {
    setShowModal(!showModal);
  }
  

  return ( progress?.systemSizeComplete 
  ? ( 
  <>
    <ProgressBar />
    <RequireUserAuth role="SALES" >
    <StepContentWrapper initial={{opacity: 0, x: 600}} animate={{opacity: 1, x: 0}}  transition={{ delay: 0.25, duration: 1 }} exit={{opacity: 0}}>
      <StepText> STEP 3 OF 3 </StepText>
      <PageHeader>System Type</PageHeader>
      <PageText>Please select the system type</PageText>
      <Form noValidate onSubmit={handleSubmit}>
        <CheckBoxRow>
          <SystemCheckBox backupChecked={backupChecked} independantChecked={independantChecked} handleChange={handleChange} />
        </CheckBoxRow>
        <FormError>{errors.systemType}</FormError>
        <ButtonWrapper>
          <BlueButton type="submit"> Next Step <InlineIcon style={{fontSize: "20px", verticalAlign: "bottom"}} icon="ic:outline-navigate-next" /></BlueButton>
        </ButtonWrapper>
      </Form>
        <PageHeader>Privacy Policy</PageHeader>
        <PageText> Nuhome takes client's personal information seriously, we use the information provided in relation to the product/service we offer </PageText>
        <PolicyPopup onClick={handleModal}>Read Our Privacy Policy  <InlineIcon style={{fontSize: "20px", paddingLeft: "5px"}} icon="carbon:popup" /></PolicyPopup>
        <Modal show={showModal} toggleShow={handleModal} />
    </StepContentWrapper>
    </RequireUserAuth >
  </>) 
  : <Navigate to="/step2" />
  )
}

export default SystemType;

const StepText = styled.div`
  color: #a4aeb4;
  font-size: 1rem;
  font-weight: 400;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 20px;
`;

const PageHeader = styled.div`
  margin-top: 40px;
  font-family: "Montserrat";
  font-weight: 700;
  font-size: 1.75rem;
  padding-bottom: 12px;
`;

const PageText = styled.div`
  color: #a4aeb4;
  line-height: 24px;
  padding-bottom: 46px;
`;

const PlaceWrapper = styled.div`
  width: 100%;
  margin-top: 10px;
`;

const PolicyPopup = styled.div`
  font-family: "Montserrat";
  background: none;
  width: 300px;
  font-size: 1rem;
  margin-bottom: 20px;
  color: #2696e8;
  cursor: pointer;
  border: none;
  outline: none:
  padding: 0;
  align-items: center;
  display: flex;
`;
  

