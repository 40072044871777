

import { StepContentWrapper } from '../Components/StepContentWrapper';
import styled from 'styled-components';
import { Form, FormRow, FormRow2Col, Form2Col, FormLabel, FormInput, FormError, FormSelect } from '../Components/Form/FormElements';
import { InlineIcon } from '@iconify/react';
import Modal from '../Components/Modal';
import { FormEvent, useState, useContext } from 'react';
import useSystemSizeCalulator from '../Hooks/useSystemSizeCalulator';
import {BlueButton} from '../Components/Buttons';
import FormattedNumberInput from '../Components/Form/FormattedNumberInput';
import ProgressBar from '../Components/ProgressBar';
import RequireUserAuth from '../Components/Auth/RequireUserAuth';
import { ClientContext } from '../Context/ClientContext';
import { Navigate } from 'react-router-dom';

const SystemSizeCalculator = () => {
  const [showModal, setShowModal] = useState(false);
  const { progress } = useContext(ClientContext);
  const {values, errors, handleChange, handleOptionsChange, handleSubmit, handleMunicipalityListRender} = useSystemSizeCalulator();

  const handleModal = () => {
    setShowModal(!showModal);
  }


  return (progress?.clientDetailComplete
    ? (
    <>
      <ProgressBar />
      <RequireUserAuth role="SALES" >
      <StepContentWrapper initial={{opacity: 0, x: 600}} animate={{opacity: 1, x: 0}}  transition={{ delay: 0.25, duration: 1 }} exit={{opacity: 0}}>
        <StepText> STEP 2 OF 3 </StepText>
        <PageHeader>System Selection Information</PageHeader>
        <PageText>Input power use in Rands: Please input your monthly electricity bill (electricity portion only, VAT included)</PageText>
        <Form noValidate onSubmit={handleSubmit}>
          <FormRow2Col>
            <Form2Col padding={true}>
            <FormattedNumberInput name="bill" value={values.bill} onChange={handleChange} />
              <FormError>{errors.bill}</FormError>
            </Form2Col>
          </FormRow2Col>
          <FormRow>
            <FormLabel htmlFor="houseSize">House Size</FormLabel>
            <FormSelect name="houseSize" onChange={handleOptionsChange}>
              <option selected disabled >Choose number of rooms</option>
              <option value={1}>1 Bedroom</option>
              <option value={2}>2 Bedroom</option>
              <option value={3}>3 Bedroom</option>
              <option value={4}>4 Bedroom</option>
              <option value={5}>5 Bedroom</option>
              <option value={3}>unknown</option>
            </FormSelect>
        <FormError>{errors.houseSize}</FormError>
            <FormLabel htmlFor="configuration">Configuration</FormLabel>
              <FormSelect name="configuration" onChange={handleOptionsChange}>
                <option selected disabled > Choose single phase or three phase</option>
                <option value={1}> Single Phase</option>
                <option value={3}> Three Phase</option>
              </FormSelect>
            <FormError>{errors.configuration}</FormError>
            <FormLabel htmlFor="roofType">Roof Type</FormLabel>
            <FormSelect name="roofType" onChange={handleOptionsChange}>
              <option selected disabled>Choose roof type</option>
              <option value={"tile"}>Tile</option>
              <option value={"ibr"}>IBR</option>
              <option value={"kliplock"}>Kliplock</option>
              <option value={"slate"}>Slate</option>
              <option value={"flatconcrete"}>Flat Concrete</option>
              <option value={"corrugated"}>Corrugated</option>
              <option value={"thatch"}>Thatch</option>
              <option value={"other"}>Other</option>
            </FormSelect>  
            <FormError>{errors.roofType}</FormError>
            <FormLabel htmlFor="province">Province</FormLabel>
            <FormSelect name="province" onChange={handleOptionsChange}>
              <option selected disabled>Choose province</option>
              <option value="gauteng">Gauteng</option>
              <option value="easterncape">Eastern Cape</option>
              <option value="freestate">Free State</option>
              <option value="kzn">KwaZulu-Natal</option>
              <option value="limpopo">Limpopo</option>
              <option value="mpumalanga">Mpumalanga</option>
              <option value="northerncape">Northern Cape</option>
              <option value="northwest">North West</option>
              <option value="northerncape">Northern Cape</option>
              <option value="westerncape">Western Cape</option>
            </FormSelect>  
            <FormError>{errors.province}</FormError>
            <FormLabel htmlFor="municipality">Municipality</FormLabel>
            {handleMunicipalityListRender()}
            <FormError>{errors.municipality}</FormError>
          </FormRow>
          <ButtonWrapper>
           <BlueButton type="submit"> Next Step <InlineIcon style={{fontSize: "20px", verticalAlign: "bottom"}} icon="ic:outline-navigate-next" /></BlueButton>
          </ButtonWrapper>
        </Form>
          <PageHeader>Privacy Policy</PageHeader>
          <PageText> Nuhome takes client's personal information seriously, we use the information provided in relation to the product/service we offer </PageText>
          <PolicyPopup onClick={handleModal}>Read Our Privacy Policy  <InlineIcon style={{fontSize: "20px", paddingLeft: "5px"}} icon="carbon:popup" /></PolicyPopup>
          <Modal show={showModal} toggleShow={handleModal} />
      </StepContentWrapper>
      </RequireUserAuth>
    </>)
    : <Navigate to="/" />
  )
}

export default SystemSizeCalculator;

const StepText = styled.div`
  color: #a4aeb4;
  font-size: 1rem;
  font-weight: 400;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 20px;
`;

const PageHeader = styled.div`
  margin-top: 40px;
  font-family: "Montserrat";
  font-weight: 700;
  font-size: 1.75rem;
  padding-bottom: 12px;
`;

const PageText = styled.div`
  color: #a4aeb4;
  line-height: 24px;
  padding-bottom: 46px;
`;

const PlaceWrapper = styled.div`
  width: 100%;
  margin-top: 10px;
`;

const PolicyPopup = styled.div`
  font-family: "Montserrat";
  background: none;
  width: 300px;
  font-size: 1rem;
  margin-bottom: 20px;
  color: #2696e8;
  cursor: pointer;
  border: none;
  outline: none:
  padding: 0;
  align-items: center;
  display: flex;
`;
  
const BackButton = styled.div`
  font-family: "Montserrat";
  font-size: 1rem;
  margin-left: 16px;
  color: #fff;
  background-color: #35a0ee;
  box-shadow: 0 0 20px rgb(53, 160, 238 / 25% );
  width: 154px;
  height: 48px;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  cursor: pointer;
  border: none;
  outline: none;
  padding: 0;
`;

