
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import ContactDetails from './Pages/ContactDetails';
import SystemSizeCalculator from './Pages/SystemSizeCalculator';
import ClientContextProvider from './Context/ClientContext';
import UserContextProvider from "./Context/UserContext";
import SystemType from './Pages/SystemType';
import Proposal from './Pages/Proposal';
import Login from "./Pages/Login/Login";


function App() {
  return (
    <UserContextProvider>
    <ClientContextProvider>
      <Router>
        <Routes>
          <Route path="/" element={<ContactDetails />} />
          <Route path="/step2" element={<SystemSizeCalculator />} />
          <Route path="/step3" element={<SystemType />} />
          <Route path="/proposal" element={<Proposal />} />
          <Route path="/login" element={<Login />} />
        </Routes>
      </Router>
    </ClientContextProvider>
    </UserContextProvider>
  );
}

export default App;
