import {
    GoogleAuthProvider,
    signInWithPopup,
    signInWithEmailAndPassword,
    createUserWithEmailAndPassword,
    sendPasswordResetEmail,
    signOut,
    getAuth
  } from "firebase/auth";
  import {
    query,
    doc,
    getDoc,
    getDocs,
    collection,
    where,
    addDoc,
    updateDoc,
    setDoc,
    onSnapshot,
  } from "firebase/firestore";
  import { firestore } from "../firebase";
  import { getStorage, ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";

const auth = getAuth();

const logInWithEmailAndPassword = async (email: string, password: string) => {
  try {
    const res = await signInWithEmailAndPassword(auth, email, password);
    const user = res.user;
    console.log(user);
    return user;
  } catch (err: any) {
    console.error(err.message);
    return err.code;
  };
};

const handleLoginWithEmailAndPasswordErrors = (error: string) => {
  if (error === 'auth/wrong-password') return 'Login failed - Wrong password';
  if (error === 'auth/user-not-found') return 'Login failed - User not found';
  if (error === 'auth/user-disabled') return 'Login failed - User has been disabled';
  if (error === 'auth/invalid-email') return 'Login failed - Invalid email format';
};

const getUserRole = async (userId: string) => {
  const docRef = doc(firestore, "users", userId);
  const role = await getDoc(docRef);
  return role.data();
};

const QueriedCollectionDetails = async(collectionName: string, field: string, value: string) => {

  const result = query(collection(firestore, collectionName), where(field, "==", value));

  const querySnapshot = await getDocs(result);
  const documents:any =[];

  querySnapshot.forEach((doc) => {

    documents.push(doc.data());
  });

  return documents;
};

const SpecicficCollectionDetails = async(userID: string, collectionName: string) => {

//This function is specifically for an individual to get only their data and no one elses throught their UserID.
//So over here, the person's userID is very important, and are restricted.
//So you need to actually specify the path pretty accurately.

    try {
    const docRef = doc(firestore, collectionName, userID);
    const docSnap = await getDoc(docRef);
  
      if (docSnap.exists()) {return docSnap.data();} //This will be passing an object not array! Thus when calling it, make sure you pass object into const and that const into an array.
      else{alert("No such document!");}
    } catch (err: any) {
      console.error("FIREBASE",err);
      alert(err.message);
    };
  };

  export {
    SpecicficCollectionDetails,
    QueriedCollectionDetails,
    logInWithEmailAndPassword,
    handleLoginWithEmailAndPasswordErrors,
    getUserRole,
  };

