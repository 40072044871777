
import { StepContentWrapper } from '../Components/StepContentWrapper';
import styled from 'styled-components';
import { Form, FormRow, FormRow2Col, Form2Col, FormLabel, FormInput, FormError} from '../Components/Form/FormElements';
import useContactDetails from '../Hooks/useContactDetails';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import { InlineIcon } from '@iconify/react';
import Modal from '../Components/Modal';
import { useState} from 'react';
import { LightBlueButton, BlueButton} from '../Components/Buttons';
import ProgressBar from '../Components/ProgressBar';
import RequireUserAuth from '../Components/Auth/RequireUserAuth';
import ToggleSwitch from '../Components/ToggleSwitch/ToggleSwitch';

const ContactDetails = () => {
  const [showModal, setShowModal] = useState(false);
  const [showCustomAddress, setShowCustomAddress] = useState(false);
  const { handleChange, handleAddressChange, handleSubmit, values, errors, loading } = useContactDetails(showCustomAddress);
  
  const handleShowCustomAddress = () => {
    console.log("It works!!");
    setShowCustomAddress(!showCustomAddress);
  }
  
  const handleModal = () => {
    setShowModal(!showModal);
  }

  const handleAdressRender  = () => {
    if (showCustomAddress === false) {
      return (
        <PlaceWrapper>
          <FormRow>
            <GooglePlacesAutocomplete
                selectProps={{value: values.address, onChange: handleAddressChange}}
                autocompletionRequest={{componentRestrictions: {country: ['za'],}}}
            />
          </FormRow>
        </PlaceWrapper>
      )
    }

    if (showCustomAddress === true) {
      return (
        <PlaceWrapper>
          <FormRow>
          <FormInput style={{marginTop: "0"}} name="customAddress" type="address" value={values.customAddress} onChange={handleChange} />
          <FormError>{errors.address}</FormError>
          </FormRow>
        </PlaceWrapper>
      )
    }            
  }


  return (
    <>
      <ProgressBar />
      <RequireUserAuth role="SALES">
      <StepContentWrapper initial={{opacity: 0, x: 600}} animate={{opacity: 1, x: 0}} exit={{opacity: 0 }}transition={{delay: 0.25, duration: 1 }}>
        <StepText> STEP 1 OF 3 </StepText>
        <PageHeader>Contact details</PageHeader>
        <PageText>Welcome to Nuhome's proposal builder, lets get started with some basic contact information.</PageText>
        <Form noValidate onSubmit={handleSubmit}>
          <FormRow2Col>
            <Form2Col padding={true}>
              <FormLabel htmlFor="name">Full Name</FormLabel>
              <FormInput name="name" type="text" value={values.name} onChange={handleChange} />
              <FormError>{errors.name}</FormError>
            </Form2Col>
            <Form2Col>
              <FormLabel htmlFor="phone">Phone Number</FormLabel>
              <FormInput name="phone" type="text" value={values.phone} onChange={handleChange} />
              <FormError>{errors.phone}</FormError>
            </Form2Col>
          </FormRow2Col>
          <FormRow>
            <FormLabel htmlFor="email">Email Address</FormLabel>
            <FormInput name="email" type="email" value={values.email} onChange={handleChange} />
            <FormError>{errors.email}</FormError>
          </FormRow>
          <CustomWrapper>
          <ToggleWrapper>
            <div>Custom Address</div>
            <ToggleSwitch name="Custom Address" checked={showCustomAddress} onChange={handleShowCustomAddress} />
          </ToggleWrapper>
          {/*<AddButton onClick={handleShowCustomAddress}> <p><CustHead>Custom Address</CustHead></p></AddButton>*/}
          < Modal show={showModal} toggleShow={handleShowCustomAddress} />
          </CustomWrapper>
          <FormRow>
          <FormLabel htmlFor="address">Property Address</FormLabel>
          {handleAdressRender()}
            <FormError>{errors.address}</FormError>
          </FormRow>
          <ButtonWrapper>
            <BlueButton> Next Step <InlineIcon style={{fontSize: "20px", verticalAlign: "bottom"}} icon="ic:outline-navigate-next" /></BlueButton>
          </ButtonWrapper>
        </Form>
          <PageHeader>Privacy Policy</PageHeader>
          <PageText> Nuhome takes client's personal information seriously, we use the information provided in relation to the product/service we offer </PageText>
          <PolicyPopup onClick={handleModal}>Expand Privacy Popup  <InlineIcon style={{fontSize: "20px", paddingLeft: "5px"}} icon="carbon:popup" /></PolicyPopup>
          <Modal show={showModal} toggleShow={handleModal} />
      </StepContentWrapper>
      </RequireUserAuth>
      </>
  )
}

export default ContactDetails;

const ToggleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: "Montserrat";
  color: #a4aeb4;
  font-size: 0.875rem;
`;

const StepText = styled.div`
  color: #a4aeb4;
  font-size: 1rem;
  font-weight: 400;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 20px;
`;

const PageHeader = styled.div`
  margin-top: 25px;
  font-family: "Montserrat";
  font-weight: 700;
  font-size: 1.75rem;
  padding-bottom: 12px;
`;

const PageText = styled.div`
  color: #a4aeb4;
  line-height: 24px;
  padding-bottom: 26px;
`;

const PlaceWrapper = styled.div`
  width: 100%;
  margin-top: 10px;
`;

const PolicyPopup = styled.button`
  font-family: "Montserrat";
  background: none;
  width: 20%;
  font-size: 1rem;
  margin-bottom: 20px;
  color: #2696e8;
  cursor: pointer;
  border: none;
  outline: none:
  padding: 0;
  align-items: center;
  display: flex;
`;
// const CustomPlaceHolder = styled.div`
// width: 100%;
// margin-top: 10px;
// `;


const CustomWrapper = styled.div`
display: flex;
justify-content: flex-end;
margin-bottom: 20px;
`;

const BackButton = styled.div`
  font-family: "Montserrat";
  font-size: 1rem;
  margin-left: 16px;
  color: #fff;
  background-color: #35a0ee;
  box-shadow: 0 0 20px rgb(53, 160, 238 / 25% );
  width: 154px;
  height: 48px;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  cursor: pointer;
  border: none;
  outline: none;
  padding: 0;
`;
