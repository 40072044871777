
import styled from "styled-components";
import logo from "../images/logo.png";
import { NUVO_LIGHT_GREY, NUVO_BLUE, NUVO_GREY } from "../utils/constants"
import { useEffect, useContext } from "react";
import { ClientContext } from "../Context/ClientContext";
import schematicImage from "../images/system_schematic.svg";
import proposalIcon from "../images/proposalSvg/design-proposal-icon-blue.svg";
import installIcon from "../images/proposalSvg/installation-icon-grey.svg";
import maintenanceIcon from "../images/proposalSvg/maintenance-icon-grey.svg";
import procurementIcon from "../images/proposalSvg/procurement-icon-grey.svg";
import handoverIcon from "../images/proposalSvg/handover-icon-grey.svg";
import surveyIcon from "../images/proposalSvg/site-survey-icon-grey.svg";
import projectIcon from "../images/proposalSvg/project-documentation-icon-grey.svg";
import clientAcceptIcon from "../images/proposalSvg/client-acceptance-icon-grey.svg";
import solarPanelImage from "../images/proposalSvg/solar-panels.svg"
import commissionIcon from "../images/proposalSvg/commissioning-icon-grey.svg";
import ignLogo from "../images/proposalSvg/ig3n-logo.svg";
import victronLogo from "../images/proposalSvg/victron-energy-logo.svg";
import canadianSolarLogo from "../images/proposalSvg/canadian-solar-logo.svg";
import TempSpinner from "../Components/TempSpinner";
import useProposal from "../Hooks/useProposal";

const formatNumberAsText = (number: number) => {
  return new Intl.NumberFormat('en-ZA', {
    style: 'currency',
    currency: 'ZAR',
    minimumFractionDigits: 0
  }).format(number);
};

const Proposal = () => {

  const { clientData, systemType, size } = useContext(ClientContext);
  const { costs, loading, totals, systemComponents, loadingText, systemList, systemListWithCosts } = useProposal();

 useEffect(() => {
   if (systemList) console.log("SYSTEM LIST:", systemList)
 },[systemList]);


  if (loading) {
    return (
      <SpinnerWrapper>
        <TempSpinner />
        <div>{loadingText}</div>
      </SpinnerWrapper>
    )
  }


 return  (
    <PageWrapper>
      <PrintWrapper>
        <IntroWrapper>
            <LogoWrapper>
              <Logo src={logo} alt="nuhome_logo"/>
            </LogoWrapper>
            <SubHeader>ENERGY STORAGE SYSTEM PROPOSAL</SubHeader>
            <Info>This proposal details a concept energy storage system. 
                The sizing of the system is based on estimated load size prior to actual 
                load measurements and site survey. Should the client agree in principle with the concept proposal, 
                a detail design phase will commence.</Info>
        </IntroWrapper>
      </PrintWrapper>
      <PageBreak />
      <PrintWrapper>
        <InfoWrapper>
            <InfoA>
                <SubHeader>CLIENT BRIEF</SubHeader>
                <ClientDetails>
                 {clientData.name}
                </ClientDetails>
                <ClientDetails>
                 {clientData.address}
                </ClientDetails>
                <Info>The mandate to NuHome is to provide an energy storage solution that will reduce grid 
                    import of energy and provide energy storage for use during night time and during power outages. 
                    The PV plant will be used for self-consumption where power produced will be used to supplement the attached 
                    loads.</Info>
            </InfoA>
           <InfoA>
            <SubHeader>INFORMATION REQUIREMENT</SubHeader>
            <Info>The offered solution was sized to cater for <b>Average Daily Consumption</b> of <b>{size.systemSize}kWh</b>.
                Based on the size of your home we recommend a <b>{systemList[1].invkWh}kW Inverter/Charger</b> and a <b>{systemList[1].batterykWh}kWh Lithium Battery</b> which will provide <b>{systemList[1].usableEnergykWh}kWh of 
                Usable Energy</b> for night time energy supply and for use during a power failure (loadshedding). 
                The energy generation component will be provided by a <b>{systemList[1].solarArraykWp}kWp</b> Solar Array which will produce on
                average <b>{systemList[1].solarYieldDaily}kWh of Solar Yield per Day</b>.
                <br/>
                <br/>
                It should be noted that the NuHome engineers can’t control the amount of sunshine that gets converted to energy, 
                the energy use or behaviour of the home occupants. With this in mind the system may from time to time draw power from the grid/generator in times of low solar irradiation or higher than normal consumption of energy within the home.
                We will as best we can help navigate and notify you with subtle nudges and prompts through our NuHome mobile application. We might one day help the grid by sharing surplus energy.</Info>
           </InfoA>
        </InfoWrapper>
      </PrintWrapper>
      <PageBreak />
      <PrintWrapper>
        <SchematicWrapper>
            <InfoWrapper>
              <InfoA>
                <SubHeader>SYSTEM SCHEMATIC</SubHeader>
                <WorkingPrinHeader>Working Principle</WorkingPrinHeader>
                <Info>Similar to a standard grid-tied system, solar power 
                    is used to supply day-time loads thereby producing savings on the energy 
                    bill. The introduction of the storage system however allows the household to
                     use stored solar energy for use during night-time. 
                     Solar power is always used to recharge the battery as first priority. 
                     Should days of cloudy weather ensue then more grid import power would be 
                     necessary. The system also allows for backup should the grid fail.
                </Info>
              </InfoA>
              <InfoA>
                  <img src={schematicImage} alt="system_image"/>
              </InfoA>
            </InfoWrapper>
        </SchematicWrapper>
      </PrintWrapper>
      <PageBreak />
      <SystemPrintWrapper>
        <SystemWrapper>
          <System>
            <SubHeader systemName={true}>{systemList[0].nuhomeSystem}</SubHeader>
            <Panels>
              <PanelImageWrapper>
                <img src={solarPanelImage} style={{maxHeight: "100%", maxWidth: "100%"}} alt="solar_panels" />
              </PanelImageWrapper>
              <Info>{systemType === 'backup' ? 'X 0' : `X ${systemList[0].moduleQty}`}</Info>
            </Panels>
            <InvBats>
              <InvBatsImageWrapper>
                <img src={systemList[0].inverterImage} style={{maxHeight: "60%", maxWidth: "60%"}} alt="victron_inverter" />
                <img src={systemList[0].batteryImage}  style={{maxHeight: "70%", maxWidth: "100%"}} alt="inverter-with-battery"/>
              </InvBatsImageWrapper>
              <Info>
                1 X {systemList[0].invkWh}kW <br/>
                Inverter <br/>
                <br/>
                1 X {systemList[0].batterykWh}kWh <br/>
                Lithium <br/>
                Battery <br/>
              </Info>
            </InvBats>
            <PriceWrapper>
              <PriceHeader> TOTAL PRICE Incl. VAT </PriceHeader>
              <PriceAmount> {new Intl.NumberFormat('en-ZA', {
                style: 'currency',
                currency: 'ZAR',
                minimumFractionDigits: 2
                }).format(systemListWithCosts[0].total_inc_vat)} 
              </PriceAmount>
            </PriceWrapper>
          </System>
          <VerticalDivider />
          <System>
            <SubHeader systemName={true}>{systemList[1].nuhomeSystem}</SubHeader>
            <Panels>
              <PanelImageWrapper>
                <img src={solarPanelImage} style={{maxHeight: "100%", maxWidth: "100%"}} alt="solar_panels" />
              </PanelImageWrapper>
              <Info>{systemType === 'backup' ? 'X 0' : `X ${systemList[1].moduleQty}`}</Info>
            </Panels>
            <InvBats>
              <InvBatsImageWrapper>
                {/*<img src={system2Image} style={{maxHeight: "100%", maxWidth: "100%"}} alt="inverter-with-battery"/>*/}
                <img src={systemList[1].inverterImage} style={{maxHeight: "60%", maxWidth: "60%"}} alt="victron_inverter" />
                <img src={systemList[1].batteryImage} style={{maxHeight: "70%", maxWidth: "100%"}} alt="inverter-with-battery"/>
              </InvBatsImageWrapper>
              <Info>
                1 X {systemList[1].invkWh}kW <br/>
                Inverter <br/>
                <br/>
                1 X {systemList[1].batterykWh}kWh <br/>
                Lithium <br/>
                Battery <br/>
              </Info>
            </InvBats>
            <PriceWrapper>
              <PriceHeader> TOTAL PRICE Incl. VAT </PriceHeader>
              <PriceAmount> {new Intl.NumberFormat('en-ZA', {
                style: 'currency',
                currency: 'ZAR',
                minimumFractionDigits: 2
                }).format(systemListWithCosts[1].total_inc_vat)} 
              </PriceAmount>
            </PriceWrapper>
          </System>
          <VerticalDivider />
          <System>
            <SubHeader systemName={true}>{systemList[2].nuhomeSystem}</SubHeader>
            <Panels>
              <PanelImageWrapper>
                <img src={solarPanelImage} style={{maxHeight: "100%", maxWidth: "100%"}} alt="solar_panels" />
              </PanelImageWrapper>
              <Info>{systemType === 'backup' ? 'X 0' : `X ${systemList[2].moduleQty}`}</Info>
            </Panels>
            <InvBats>
              <InvBatsImageWrapper>
                {/*<img src={system3Image} style={{maxHeight: "100%", maxWidth: "100%"}} alt="inverter-with-battery"/>*/}
                <img src={systemList[2].inverterImage}  style={{maxHeight: "60%", maxWidth: "60%"}} alt="victron_inverter" />
                <img src={systemList[2].batteryImage} style={{maxHeight: "70%", maxWidth: "100%"}} alt="inverter-with-battery"/>
              </InvBatsImageWrapper>
              <Info>
                1 X {systemList[2].invkWh}kW <br/>
                Inverter <br/>
                <br/>
                1 X {systemList[2].batterykWh}kWh <br/>
                Lithium <br/>
                Battery <br/>
              </Info>
            </InvBats>
            <PriceWrapper>
              <PriceHeader> TOTAL PRICE Incl. VAT </PriceHeader>
              <PriceAmount> {new Intl.NumberFormat('en-ZA', {
                style: 'currency',
                currency: 'ZAR',
                minimumFractionDigits: 2
                }).format(systemListWithCosts[2].total_inc_vat)} 
              </PriceAmount>
            </PriceWrapper>
          </System>
        </SystemWrapper>
      </SystemPrintWrapper>
      <SystemOptionsPrintWrapper>
        <InfoWrapper addMargin={"90px"}>
        <InfoCentered>
            <SubHeader systemName={true}>{systemList[0].nuhomeSystem}</SubHeader>
            <SystemImageWrapper>
              <img src={systemType === 'backup' ? systemList[0].backupImage : systemList[0].summaryImage} alt="system" style={{maxWidth: "100%", maxHeight: "100%"}} />

            </SystemImageWrapper>
            <Info>
              <li>{systemList[0].moduleQty + ' ' + 'x' + ' ' + systemList[0].moduleType}</li>
              <li>{`1 x ${systemList[0].inverterType}`}</li>
              <li>{`1 x ${systemList[0].controller}`}</li>
              <li>{`${systemList[0].batteryQty} x 5kWh LiFePO4 battery`}</li>
            </Info>
            <SupplierImageWrapper>
              <div style={{height: "40%", width: "40%", paddingRight: "5%"}}>
               <img src={ignLogo} alt="ign-logo"/>
              </div>
              <div style={{height: "32%", width: "32%", paddingRight: "5%"}}>
               <img src={canadianSolarLogo} alt="ign-logo"/>
              </div>
              <div style={{height: "40%", width: "40%"}}>
               <img src={victronLogo} alt="ign-logo"/>
              </div>
            </SupplierImageWrapper>
        </InfoCentered>
            <InfoCentered>
              <CostingTable>
                <tbody>
                <TableRow>
                  <CostingTableHeader>SUMMARY</CostingTableHeader>
                  <CostingTableHeader>PRICE (EXCL VAT)</CostingTableHeader>
                </TableRow>
                <TableRow>
                  <CostingTdItem>PV Modules</CostingTdItem>
                  <CostingTdValue>{formatNumberAsText(systemListWithCosts[0].pv)}</CostingTdValue> 
                </TableRow>
                    <TableRow>
                      <CostingTdItem>Mounting Structure</CostingTdItem>
                      <CostingTdValue>{formatNumberAsText(systemListWithCosts[0].mounting)} </CostingTdValue>
                    </TableRow>
                    <TableRow>
                      <CostingTdItem>Inverters & Accessories</CostingTdItem>
                      <CostingTdValue>{formatNumberAsText(systemListWithCosts[0].inverters)}</CostingTdValue>
                    </TableRow>
                    <TableRow>
                      <CostingTdItem>AC Combiner Box</CostingTdItem>
                      <CostingTdValue>{formatNumberAsText(systemListWithCosts[0].ac_box)}</CostingTdValue>
                    </TableRow>
                    <TableRow>
                      <CostingTdItem>DC Combiner Box</CostingTdItem>
                      <CostingTdValue>{formatNumberAsText(systemListWithCosts[0].dc_box)}</CostingTdValue>
                    </TableRow>
                    <TableRow>
                      <CostingTdItem>Storage</CostingTdItem>
                      <CostingTdValue>{formatNumberAsText(systemListWithCosts[0].storage)}</CostingTdValue>
                    </TableRow>
                    <TableRow>
                        <CostingTdItem>System Cabling</CostingTdItem>
                        <CostingTdValue>{formatNumberAsText(systemListWithCosts[0].system_cabling)}</CostingTdValue>
                      </TableRow>
                      <TableRow>
                        <CostingTdItem>Installation</CostingTdItem>
                        <CostingTdValue>{formatNumberAsText(systemListWithCosts[0].installation)}</CostingTdValue>
                      </TableRow>
                      <TableRow>
                        <CostingTdItem>Installation Consumables</CostingTdItem>
                        <CostingTdValue>{formatNumberAsText(systemListWithCosts[0].consumables)}</CostingTdValue>
                      </TableRow>
                      <TableRow>
                        <CostingTdItem>COC Amendment</CostingTdItem>
                        <CostingTdValue>{formatNumberAsText(systemListWithCosts[0].coc)}</CostingTdValue>
                      </TableRow>
                      <TableRow>
                        <CostingTdItem>TOTAL PRICE EXCL VAT</CostingTdItem>
                        <CostingTdValue>{formatNumberAsText(systemListWithCosts[0].total_ex_vat)} </CostingTdValue>
                      </TableRow>
                      <TableRow>
                        <CostingTdItem>VAT</CostingTdItem>
                        <CostingTdValue>{formatNumberAsText(systemListWithCosts[0].vat)}</CostingTdValue>
                      </TableRow>
                      <TableRow>
                        <CostingTdItem bold={true}>TOTAL PRICE INCL VAT</CostingTdItem>
                        <CostingTdValue bold={true}>{formatNumberAsText(systemListWithCosts[0].total_inc_vat)}</CostingTdValue>
                      </TableRow>
                      </tbody>
              </CostingTable>
            </InfoCentered> 
        </InfoWrapper>
      </SystemOptionsPrintWrapper>
      <SystemOptionsPrintWrapper>
        <InfoWrapper addMargin={"90px"}>
        <InfoCentered>
            <SubHeader systemName={true}>{systemList[1].nuhomeSystem}</SubHeader>
            <SystemImageWrapper>
              <img src={systemType === 'backup' ? systemList[1].backupImage : systemList[1].summaryImage} alt="system" style={{maxWidth: "100%", maxHeight: "100%"}} />
            </SystemImageWrapper>
            <Info>
              <li>{systemList[1].moduleQty + ' ' + 'x' + ' ' + systemList[1].moduleType}</li>
              <li>{`1 x ${systemList[1].inverterType}`}</li>
              <li>{`1 x ${systemList[1].controller}`}</li>
              <li>{`${systemList[1].batteryQty} x 5kWh LiFePO4 battery`}</li>
            </Info>
            <SupplierImageWrapper>
              <div style={{height: "40%", width: "40%", paddingRight: "5%"}}>
               <img src={ignLogo} alt="ign-logo"/>
              </div>
              <div style={{height: "32%", width: "32%", paddingRight: "5%"}}>
               <img src={canadianSolarLogo} alt="ign-logo"/>
              </div>
              <div style={{height: "40%", width: "40%"}}>
               <img src={victronLogo} alt="ign-logo"/>
              </div>
            </SupplierImageWrapper>
        </InfoCentered>
            <InfoCentered>
              <CostingTable>
                <tbody>
                <TableRow>
                  <CostingTableHeader>SUMMARY</CostingTableHeader>
                  <CostingTableHeader>PRICE (EXCL VAT)</CostingTableHeader>
                </TableRow>
                <TableRow>
                  <CostingTdItem>PV Modules</CostingTdItem>
                  <CostingTdValue>{formatNumberAsText(systemListWithCosts[1].pv)}</CostingTdValue> 
                </TableRow>
                    <TableRow>
                      <CostingTdItem>Mounting Structure</CostingTdItem>
                      <CostingTdValue>{formatNumberAsText(systemListWithCosts[1].mounting)} </CostingTdValue>
                    </TableRow>
                    <TableRow>
                      <CostingTdItem>Inverters & Accessories</CostingTdItem>
                      <CostingTdValue>{formatNumberAsText(systemListWithCosts[1].inverters)}</CostingTdValue>
                    </TableRow>
                    <TableRow>
                      <CostingTdItem>AC Combiner Box</CostingTdItem>
                      <CostingTdValue>{formatNumberAsText(systemListWithCosts[1].ac_box)}</CostingTdValue>
                    </TableRow>
                    <TableRow>
                      <CostingTdItem>DC Combiner Box</CostingTdItem>
                      <CostingTdValue>{formatNumberAsText(systemListWithCosts[1].dc_box)}</CostingTdValue>
                    </TableRow>
                    <TableRow>
                      <CostingTdItem>Storage</CostingTdItem>
                      <CostingTdValue>{formatNumberAsText(systemListWithCosts[1].storage)}</CostingTdValue>
                    </TableRow>
                    <TableRow>
                        <CostingTdItem>System Cabling</CostingTdItem>
                        <CostingTdValue>{formatNumberAsText(systemListWithCosts[1].system_cabling)}</CostingTdValue>
                      </TableRow>
                      <TableRow>
                        <CostingTdItem>Installation</CostingTdItem>
                        <CostingTdValue>{formatNumberAsText(systemListWithCosts[1].installation)}</CostingTdValue>
                      </TableRow>
                      <TableRow>
                        <CostingTdItem>Installation Consumables</CostingTdItem>
                        <CostingTdValue>{formatNumberAsText(systemListWithCosts[1].consumables)}</CostingTdValue>
                      </TableRow>
                      <TableRow>
                        <CostingTdItem>COC Amendment</CostingTdItem>
                        <CostingTdValue>{formatNumberAsText(systemListWithCosts[1].coc)}</CostingTdValue>
                      </TableRow>
                      <TableRow>
                        <CostingTdItem>TOTAL PRICE EXCL VAT</CostingTdItem>
                        <CostingTdValue>{formatNumberAsText(systemListWithCosts[1].total_ex_vat)} </CostingTdValue>
                      </TableRow>
                      <TableRow>
                        <CostingTdItem>VAT</CostingTdItem>
                        <CostingTdValue>{formatNumberAsText(systemListWithCosts[1].vat)}</CostingTdValue>
                      </TableRow>
                      <TableRow>
                        <CostingTdItem bold={true}>TOTAL PRICE INCL VAT</CostingTdItem>
                        <CostingTdValue bold={true}>{formatNumberAsText(systemListWithCosts[1].total_inc_vat)}</CostingTdValue>
                      </TableRow>
                      </tbody>
              </CostingTable>
            </InfoCentered> 
        </InfoWrapper>
      </SystemOptionsPrintWrapper>
      <SystemOptionsPrintWrapper>
        <InfoWrapper addMargin={"90px"}>
        <InfoCentered>
            <SubHeader systemName={true}>{systemList[2].nuhomeSystem}</SubHeader>
            <SystemImageWrapper>
              <img src={systemType === 'backup' ? systemList[2].backupImage : systemList[2].summaryImage} alt="system" style={{maxWidth: "100%", maxHeight: "100%"}} />
            </SystemImageWrapper>
            <Info>
              <li>{systemList[2].moduleQty + ' ' + 'x' + ' ' + systemList[2].moduleType}</li>
              <li>{`1 x ${systemList[2].inverterType}`}</li>
              <li>{`1 x ${systemList[2].controller}`}</li>
              <li>{`${systemList[2].batteryQty} x 5kWh LiFePO4 battery`}</li>
            </Info>
            <SupplierImageWrapper>
              <div style={{height: "40%", width: "40%", paddingRight: "5%"}}>
               <img src={ignLogo} alt="ign-logo"/>
              </div>
              <div style={{height: "32%", width: "32%", paddingRight: "5%"}}>
               <img src={canadianSolarLogo} alt="ign-logo"/>
              </div>
              <div style={{height: "40%", width: "40%"}}>
               <img src={victronLogo} alt="ign-logo"/>
              </div>
            </SupplierImageWrapper>
        </InfoCentered>
            <InfoCentered>
              <CostingTable>
                <tbody>
                <TableRow>
                  <CostingTableHeader>SUMMARY</CostingTableHeader>
                  <CostingTableHeader>PRICE (EXCL VAT)</CostingTableHeader>
                </TableRow>
                <TableRow>
                  <CostingTdItem>PV Modules</CostingTdItem>
                  <CostingTdValue>{formatNumberAsText(systemListWithCosts[2].pv)}</CostingTdValue> 
                </TableRow>
                    <TableRow>
                      <CostingTdItem>Mounting Structure</CostingTdItem>
                      <CostingTdValue>{formatNumberAsText(systemListWithCosts[2].mounting)} </CostingTdValue>
                    </TableRow>
                    <TableRow>
                      <CostingTdItem>Inverters & Accessories</CostingTdItem>
                      <CostingTdValue>{formatNumberAsText(systemListWithCosts[2].inverters)}</CostingTdValue>
                    </TableRow>
                    <TableRow>
                      <CostingTdItem>AC Combiner Box</CostingTdItem>
                      <CostingTdValue>{formatNumberAsText(systemListWithCosts[2].ac_box)}</CostingTdValue>
                    </TableRow>
                    <TableRow>
                      <CostingTdItem>DC Combiner Box</CostingTdItem>
                      <CostingTdValue>{formatNumberAsText(systemListWithCosts[2].dc_box)}</CostingTdValue>
                    </TableRow>
                    <TableRow>
                      <CostingTdItem>Storage</CostingTdItem>
                      <CostingTdValue>{formatNumberAsText(systemListWithCosts[2].storage)}</CostingTdValue>
                    </TableRow>
                    <TableRow>
                        <CostingTdItem>System Cabling</CostingTdItem>
                        <CostingTdValue>{formatNumberAsText(systemListWithCosts[2].system_cabling)}</CostingTdValue>
                      </TableRow>
                      <TableRow>
                        <CostingTdItem>Installation</CostingTdItem>
                        <CostingTdValue>{formatNumberAsText(systemListWithCosts[2].installation)}</CostingTdValue>
                      </TableRow>
                      <TableRow>
                        <CostingTdItem>Installation Consumables</CostingTdItem>
                        <CostingTdValue>{formatNumberAsText(systemListWithCosts[2].consumables)}</CostingTdValue>
                      </TableRow>
                      <TableRow>
                        <CostingTdItem>COC Amendment</CostingTdItem>
                        <CostingTdValue>{formatNumberAsText(systemListWithCosts[2].coc)}</CostingTdValue>
                      </TableRow>
                      <TableRow>
                        <CostingTdItem>TOTAL PRICE EXCL VAT</CostingTdItem>
                        <CostingTdValue>{formatNumberAsText(systemListWithCosts[2].total_ex_vat)} </CostingTdValue>
                      </TableRow>
                      <TableRow>
                        <CostingTdItem>VAT</CostingTdItem>
                        <CostingTdValue>{formatNumberAsText(systemListWithCosts[2].vat)}</CostingTdValue>
                      </TableRow>
                      <TableRow>
                        <CostingTdItem bold={true}>TOTAL PRICE INCL VAT</CostingTdItem>
                        <CostingTdValue bold={true}>{formatNumberAsText(systemListWithCosts[2].total_inc_vat)}</CostingTdValue>
                      </TableRow>
                      </tbody>
              </CostingTable>
            </InfoCentered> 
        </InfoWrapper>
      </SystemOptionsPrintWrapper>
      <PageBreak />
      <PrintWrapper>
        <InfoWrapper>
          <InfoWarranty>
            <SubHeader>SYSTEM BUDGET COSTING</SubHeader>
            <Info>
            The installation cost is based on Gauteng and surrounding areas.<br/>
            <br/>
            The costing for the balance of systems has been standardised,
            once the site survey has been conducted re-costing might be necessary.<br/>
            <br/>
            Costing is valid for 14 days.</Info>
          </InfoWarranty>
          <InfoWarranty>
            <Info>
              <SubHeader>EQUIPMENT LEADTIMES</SubHeader>
              <p>Inverters - 1 week, ex stock</p>
              <p>Batteries - 2 weeks, ex stock</p>
              <p>Canadian Solar Modules - 1 week, ex stock</p>
              <p>/ 8 weeks for import</p>
              <p>Balance of Systems - 1 week</p>
              <p>Racking - 1 week</p>
              <SubHeader>EQUIPMENT WARRANTIES</SubHeader>
              <p>Victron Equipment - 5 years</p>
              <p>Canadian Solar Modules - 12 years service</p>
              <p>warranty / 25 year performance warranty</p>
              <p>Racking - 10 years</p>
              <p>i-G3N - 10 years</p>
              <p>Workmanship - 1 year</p>
            </Info>
          </InfoWarranty>
        </InfoWrapper>
        </PrintWrapper>
        <PageBreak />
        <LastPagePrintWrapper>
          <MultiHeaderWrapper>
            <SubHeader>Payment Terms</SubHeader>
            <SubHeader>Project Milestones</SubHeader>
          </MultiHeaderWrapper>
        <InfoWrapper>   
        <InfoCentered>
            <PaymentTable>
            <tbody>
            <TableRow>
              <CostingTableHeader>MILESTONE</CostingTableHeader>
              <CostingTableHeader>DESCRIPTION</CostingTableHeader>
              <CostingTableHeader>% PAYMENT</CostingTableHeader>
            </TableRow>
                  <TableRow>
                    <PaymentTd>Signing of Agreement</PaymentTd>
                    <PaymentTdDescription>The effective start date will be triggered after signing of the 
                      agreement by all parties after which the deposit will be paid by the 
                      client and thereafter the procurement process will start.</PaymentTdDescription>
                    <PaymentTd>80%</PaymentTd>
                  </TableRow>
                  <TableRow>
                    <PaymentTd>Materials</PaymentTd>
                    <PaymentTdDescription>On the delivery of materials to the designated Site or alternatively 
                      the holding/store facility until installation commences on Site.​</PaymentTdDescription>
                    <PaymentTd>10%</PaymentTd>
                  </TableRow>
                  <TableRow>
                    <PaymentTd>Installation & Commissioning</PaymentTd>
                    <PaymentTdDescription>After the successful installation, the system will be tested and 
                      commissioned by Nuvo Energy. Client sign-off and handover of the 
                      fully operational system.</PaymentTdDescription>
                    <PaymentTd>10%</PaymentTd>
                  </TableRow>
                  <TableRow>
                    <PaymentTd>TOTAL</PaymentTd>
                    <PaymentTdDescription></PaymentTdDescription>
                    <PaymentTd>100%</PaymentTd>
                  </TableRow>
                  </tbody>
            </PaymentTable>
            </InfoCentered>
            <InfoMilestone>
              <Info>
                <MilestoneTableRow>
                  <TableIcon><img src={proposalIcon} alt="proposal-icon" width={"50"}height={"50"}/></TableIcon>
                  <TableText done={true}>Design & Proposal</TableText>
                </MilestoneTableRow>
                <MilestoneTableRow>
                  <TableIcon><img src={clientAcceptIcon} alt="client-icon" width={"50"}height={"50"}/></TableIcon>
                  <TableText>Client Acceptance</TableText>
                </MilestoneTableRow>
                <MilestoneTableRow>
                  <TableIcon><img src={projectIcon} alt="home-inspection-icon" width={"50"}height={"50"}/></TableIcon>
                  <TableText>Home Inspection</TableText>
                </MilestoneTableRow>
                <MilestoneTableRow>
                  <TableIcon><img src={surveyIcon} alt="tick-icon" width={"50"}height={"50"}/></TableIcon>
                  <TableText>Project Documentation</TableText>
                </MilestoneTableRow>
                <MilestoneTableRow>
                  <TableIcon><img src={procurementIcon} alt="procurement-icon" width={"50"}height={"50"}/></TableIcon>
                  <TableText>Procurement</TableText>
                </MilestoneTableRow>
                <MilestoneTableRow>
                  <TableIcon><img src={installIcon} alt="installation-icon" width={"50"}height={"50"}/></TableIcon>
                  <TableText>Installation</TableText>
                </MilestoneTableRow>
                <MilestoneTableRow>
                  <TableIcon><img src={commissionIcon} alt="commission-icon" width={"50"}height={"50"}/></TableIcon>
                  <TableText>Commissioning</TableText>
                </MilestoneTableRow>
                <MilestoneTableRow>
                  <TableIcon><img src={handoverIcon} alt="handover-icon" width={"50"}height={"50"}/></TableIcon>
                  <TableText>Handover</TableText>
                </MilestoneTableRow>
                <MilestoneTableRow>
                  <TableIcon><img src={maintenanceIcon} alt="maintenance-icon" width={"50"}height={"50"}/></TableIcon>
                  <TableText>Design & Proposal</TableText>
                </MilestoneTableRow>
            </Info>
          </InfoMilestone>
          </InfoWrapper>
        </LastPagePrintWrapper>
        <FooterWrapper>
          <Logo  src={logo} alt="nuhome_logo" width={"200"} height={"60"}/>
          <div>4 Sandown Valley, Sandton, Johannesburg, South Africa, 2196</div>
        </FooterWrapper>
        <PageBreak />
    </PageWrapper>
 )

}

export default Proposal;

const SpinnerWrapper = styled.div`
  display: flex;
  height: 100vh;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Image = styled.div`
  max-width: 100%;
  max-hieght: 100%;
`;

const FooterWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 50px;
`;

const MilestoneTableRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
`

const TableIcon = styled.div`
  width: 30%;
`;

interface TableTextProps {
  done?: boolean;  
};

const TableText = styled.div<TableTextProps>`
  padding-left: 60px;
  font-size: 1.2rem;
  width: 70%;
  color: ${props => props.done ? `${NUVO_BLUE}` : `${NUVO_GREY}` };
`;

const PageWrapper = styled.div`
  background-color: ${NUVO_LIGHT_GREY};
  @media print {
      @page {
            size: landscape;
      } 
    }
`;


const IntroWrapper = styled.div`
  margin: 0 240px 0 240px;
  padding-top: 300px;
  margin-bottom: 300px;
  @media print { 
    padding: 0;
    margin: 0;
    width: 75%;
  }
`;
const LogoWrapper = styled.div`
  display: flex;
  justify-content: center;
`

const Logo = styled.img`
`;

const TableRow = styled.tr`
  width: 100%;
  border: 1px solid ${NUVO_GREY};
`;

interface subHeaderProps {
  systemName?: boolean
}

const SubHeader = styled.h2<subHeaderProps>`
  text-align: center;
  text-transform: ${props => props.systemName ? 'none': 'uppercase'};
  color: ${NUVO_BLUE};
  font-weight: 700;
`;

const Info = styled.p`
 font-size: 1.2rem;
 text-align: justify;
  color: ${NUVO_GREY};
`;

interface InfoProps {
  addMargin?: string    
}

const InfoWrapper = styled.div<InfoProps>`
  display: flex;
  margin-top: ${props => props.addMargin || '0'};
  margin-bottom: 200px;
  text-align: center;
  justify-content: space-evenly;
  @media print {
    margin 0;
  }
`;

const InfoA = styled.div`
  padding: 8px;
  width: 450px;
`;


const InfoWarranty = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 42%;
`;

const InfoCentered = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 42%;
  @media print {
    width: 50%;
  }
`;

const InfoMilestone = styled.div`
  width: 42%;
`;


const InfoCenteredHide = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 450px;
  @media (max-width: 580px) {
      display: none;
  }
  @media print {
    display: block;
  }
`;

const ClientDetails = styled.div`
  text-align: left;
  font-family: "Montserrat";
  color: ${NUVO_GREY};
  font-weight: bold;
  font-size: 1.25rem;
  margin-bottom: 10px;
`;

const SchematicWrapper = styled.div`
  text-align: center;
`;


const SystemHeaderWrapper = styled.div`
  margin-top: 80px;
  display: flex;
  justify-content: center;
  color: ${NUVO_BLUE};
`;

const WorkingPrinHeader = styled.h2`
text-align: center;
  color: gray;
`;

const CostingTable = styled.table`
  font-family: Montserrat, sans-serif;
  color: ${NUVO_GREY};
  font-weight: bold;
  border-spacing: 0 1em;
  border: 1px solid ${NUVO_GREY};
  border-collapse: collapse;
  width: 100%;
`;

const CostingTableHeader = styled.th`
  color: #282828;
  padding: 10px;
`;

interface ITable {
  bold?: boolean
}

const CostingTdItem = styled.td<ITable>`
 width: 60%;
 text-align: left;
 padding: 10px;
 border: 1px solid ${NUVO_GREY};
 font-weight: ${props => props.bold ? '900' : '500'}
`;

const CostingTdValue = styled.td<ITable>`
 width: 40%; 
 text-align: right;
 padding-right: 10px;
 font-weight: ${props => props.bold ? '900' : '500'}
`;

const PaymentTd = styled.td`
  width: 25%;
  padding: 10px;
`;

const PaymentTdDescription = styled.td`
  padding: 10px;
  width: 50%;
  @media print {
    font-size: 0.7rem;
  }
`;

const PaymentTable = styled.table`
  font-family: Montserrat, sans-serif;
  border: 1px solid ${NUVO_GREY};
  border-collapse: collapse;
  width: 80%;
`;

const PageBreak = styled.p`
  page-break-after: always;
`;

const PrintWrapper = styled.div`
  @media print {
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

const LastPagePrintWrapper = styled.div`
  @media print {
  }
`;

const SystemPrintWrapper = styled.div`
  @media print { 
    margin: 0;
    padding: 0;
  }
`;

const SystemWrapper = styled.div`
  display: flex;
  margin-top: 10px;
  justify-content: space-between;
  height: 800px;
`;

const System = styled.div`
  width: 30%;
  height: 100%;
`; 
const Panels = styled.div`
  display:flex;
  justify-content: space-around;
  height: 35%;
  align-items: center;
`;

const PanelImageWrapper = styled.div`
  height: 100%;
  width: 70%;
`;

const InvBats = styled.div`
  height: 40%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
`;

const InvBatsImageWrapper = styled.div`
  height: 100%;
  width: 40%;
`;

const PriceHeader = styled.div`
  font-size: 1.1rem;
  color: ${NUVO_GREY};
  @media print {
      margin: 0;
    }
`;

const PriceAmount = styled.div`
  font-size: 1.8rem;
  color: ${NUVO_GREY};
  font-weight: bold;
  @media print {
      font-size: 1.5rem;
    }
`

const PriceWrapper = styled.div`
  height: 20%;
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  @media print {
      margin: 8px;
    }
`;

const VerticalDivider = styled.hr`
  height: 90%;
  width: 0.5px;
  margin-top: 50px;
  background-color: ${NUVO_GREY};
  opacity: 0.25;
`;

const SystemImageWrapper = styled.div`
  height: 400px;
  width: 100%;
`;

const SupplierImageWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 80px;
  width: 100%
`;

const SystemOptionsPrintWrapper = styled.div`
  @media print {
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

const MultiHeaderWrapper = styled.div`
  display: flex;
  justify-content: space-evenly;
`;

