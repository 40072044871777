
import styled from 'styled-components';

export const Form = styled.form`
  padding-bottom: 18px;
  display: flex;
  flex-direction: column;
`;

interface IProps {
    padding?: boolean;
}

export const Form2Col = styled.div<IProps>`
  width: 50%;
  padding-right: ${props => props.padding ? '20px' : '0px'}
`;


export const FormRow = styled.div`
  margin-bottom: 36px;
`;

export const CheckBoxRow = styled.div`
  margin-bottom: 36px;
  display: flex;
  justify-content: space-evenly;

`;

export const FormRow2Col = styled.div`
  margin-bottom: 36px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const FormLabel = styled.label`
  font-family: "Montserrat";
  color: #a4aeb4;
  font-size: 0.875rem;
`;

export const FormInput = styled.input`
  margin-top: 4px;
  padding: 0;
  width: 100%;
  height: 32px;
  border: none;
  outline: none;
  border-bottom: 1px solid #d5d9dc;
`;

export const FormSelect = styled.select`
  font-family: "Montserrat";
  font-size: 1rem;
  margin-top: 4px;
  margin-bottom: 8px;
  padding: 0;
  width: 100%;
  height: 32px;
  border: none;
  outline: none;
  border-bottom: 1px solid #d5d9dc;
`;

export const FormError = styled.div`
  color: red;
  font-size: 0.875rem;
  height: 0.875rem;
`;


