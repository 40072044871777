
import { useContext, useEffect, useState} from "react";
import { ClientContext } from "../Context/ClientContext";
import { firestore } from "../firebase";
import { doc, getDoc, DocumentData } from "firebase/firestore";
import nuhome10 from "../images/proposalSvg/nuhome-10-detail-page.png";
import nuhome10backup from "../images/proposalSvg/nuhome-10-detail-backup.png";
import nuhome10Battery from "../images/proposalSvg/nuhome-10-summary-battery.png";
import nuhome15 from "../images/proposalSvg/nuhome-15-detail-page.png";
import nuhome15backup from "../images/proposalSvg/nuhome-15-detail-backup.png";
import nuhome15Battery from "../images/proposalSvg/nuhome-15-summary-battery.png";
import nuhome20 from "../images/proposalSvg/nuhome-20-detail-page.png";
import nuhome20backup from "../images/proposalSvg/nuhome-20-detail-backup.png";
import nuhome20Battery from "../images/proposalSvg/nuhome-20-summary-battery.png";
import nuhome30 from "../images/proposalSvg/nuhome-30-detail-page.png";
import nuhome30backup from "../images/proposalSvg/nuhome-30-detail-backup.png";
import nuhome30Battery from "../images/proposalSvg/nuhome-30-summary-battery.png";
import nuhome40 from "../images/proposalSvg/nuhome-40-detail-page.png";
import nuhome40backup from "../images/proposalSvg/nuhome-40-detail-backup.png";
import nuhome40Battery from "../images/proposalSvg/nuhome-40-summary-battery.png";
import nuhome45 from "../images/proposalSvg/nuhome-45-detail-page.png";
import nuhome45backup from "../images/proposalSvg/nuhome-45-detail-backup.png";
import nuhome45Battery from "../images/proposalSvg/nuhome-45-summary-battery.png";
import nuhome60 from "../images/proposalSvg/nuhome-60-detail-page.png";
import nuhome60backup from "../images/proposalSvg/nuhome-60-detail-backup.png";
import nuhome60Battery from "../images/proposalSvg/nuhome-60-summary-battery.png";
import nuhome75 from "../images/proposalSvg/nuhome-75-detail-page.png";
import nuhome75backup from "../images/proposalSvg/nuhome-75-detail-backup.png";
import nuhome75Battery from "../images/proposalSvg/nuhome-75-summary-battery.png";
import inverterImage from "../images/proposalSvg/nuhome-summary-inverter.png";




const nuHomeSystemComponents = {
  nuhome10: {
    nuhomeSystem: "NuHome 10",
    moduleType: "Canadian 455W Mono Modules",
    moduleQty: 8,
    productWarranty: 12,
    performanceWarranty: 25,
    inverterType: "Victron Multiplus II 3kVA Inverter ",
    invkWh: 3,
    mpptType: "Victron Smartsolar 250/70 MPPT",
    controller: "Venus GX Control",
    victronWarranty: 5,
    batteryType: "IG3N battery",
    batteryQty: 1,
    batterykWh: 5.4,
    usableEnergykWh: 4.32,
    solarArraykWp: 3.6,
    solarYieldDaily: 21.6,
    batteryWarranty: 10,
    inverterImage: inverterImage,
    batteryImage: nuhome10Battery,
    summaryImage: nuhome10,
    backupImage: nuhome10backup

  },
  nuhome15: {
    nuhomeSystem: "NuHome 15",
    moduleType: "Canadian 455W Mono Modules",
    moduleQty: 10,
    productWarranty: 12,
    performanceWarranty: 25,
    inverterType: "Victron Multiplus II 3kVA Inverter ",
    invkWh: 3,
    mpptType: "Victron Smartsolar 250/85 MPPT",
    controller: "Venus GX Control",
    victronWarranty: 5,
    batteryType: "IG3N battery",
    batteryQty: 2,
    batterykWh: 10.8,
    usableEnergykWh: 8.64,
    solarArraykWp: 4.5,
    solarYieldDaily: 27,
    batteryWarranty: 10,
    inverterImage: inverterImage,
    batteryImage: nuhome15Battery,
    summaryImage: nuhome15,
    backupImage: nuhome15backup

  },
  nuhome20: {
    nuhomeSystem: "NuHome 20",
    moduleType: "Canadian 455W Mono Modules",
    moduleQty: 12,
    productWarranty: 12,
    performanceWarranty: 25,
    inverterType: "Victron Multiplus II 5kVA Inverter ",
    invkWh: 5,
    mpptType: "Victron Smartsolar 250/85 MPPT",
    controller: "Venus GX Control",
    victronWarranty: 5,
    batteryType: "IG3N battery",
    batteryQty: 2,
    batterykWh: 10.8,
    usableEnergykWh: 8.64,
    solarArraykWp: 5.4,
    solarYieldDaily: 32.4,
    batteryWarranty: 10,
    inverterImage: inverterImage,
    batteryImage: nuhome20Battery,
    summaryImage: nuhome20,
    backupImage: nuhome20backup
  },
  nuhome30: {
    nuhomeSystem: "NuHome 30",
    moduleType: "Canadian 455W Mono Modules",
    moduleQty: 16,
    productWarranty: 12,
    performanceWarranty: 25,
    inverterType: "Victron Multiplus II 5kVA Inverter ",
    invkWh: 5,
    mpptType: "Victron Smartsolar 250/100 MPPT",
    controller: "Venus GX Control",
    victronWarranty: 5,
    batteryType: "IG3N battery",
    batteryQty: 3,
    batterykWh: 16.2,
    usableEnergykWh: 12.96,
    solarArraykWp: 7.2,
    solarYieldDaily: 43.2,
    batteryWarranty: 10,
    inverterImage: inverterImage,
    batteryImage: nuhome30Battery,
    summaryImage: nuhome30,
    backupImage: nuhome30backup

  },
  nuhome40: {
    nuhomeSystem: "NuHome 40",
    moduleType: "Canadian 455W Mono Modules",
    moduleQty: 16,
    productWarranty: 12,
    performanceWarranty: 25,
    inverterType: "Victron Multiplus II 5kVA Inverter ",
    invkWh: 5,
    mpptType: "Victron Smartsolar 250/100 MPPT",
    controller: "Venus GX Control",
    victronWarranty: 5,
    batteryType: "IG3N battery",
    batteryQty: 4,
    batterykWh: 21.6,
    usableEnergykWh: 17.28,
    solarArraykWp: 9,
    solarYieldDaily: 54,
    batteryWarranty: 10,
    inverterImage: inverterImage,
    batteryImage: nuhome40Battery,
    summaryImage: nuhome40,
    backupImage: nuhome40backup
  },
  nuhome45: {
    nuhomeSystem: "NuHome 45",
    moduleType: "Canadian 455W Mono Modules",
    moduleQty: 24,
    productWarranty: 12,
    performanceWarranty: 25,
    inverterType: "Victron Quatro 8kVA Inverter ",
    invkWh: 8,
    mpptType: "Victron Smartsolar 250/100 MPPT",
    controller: "Venus GX Control",
    victronWarranty: 5,
    batteryType: "IG3N battery",
    batteryQty: 4,
    batterykWh: 21.6,
    usableEnergykWh: 17.28,
    solarArraykWp: 10.8,
    solarYieldDaily: 64.8,
    batteryWarranty: 10,
    inverterImage: inverterImage,
    batteryImage: nuhome45Battery,
    summaryImage: nuhome45,
    backupImage: nuhome45backup
  },
  nuhome60: {
    nuhomeSystem: "NuHome 60",
    moduleType: "Canadian 455W Mono Modules",
    moduleQty: 36,
    productWarranty: 12,
    performanceWarranty: 25,
    inverterType: "Victron Quatro 10kVA Inverter",
    invkWh: 10,
    mpptType: "Victron Smartsolar 250/200 MPPT",
    controller: "Venus GX Control",
    victronWarranty: 5,
    batteryType: "IG3N battery",
    batteryQty: 6,
    batterykWh: 27,
    usableEnergykWh: 21.6,
    solarArraykWp: 16.2,
    solarYieldDaily: 97.2,
    batteryWarranty: 10,
    inverterImage: inverterImage,
    batteryImage: nuhome60Battery,
    summaryImage: nuhome60,
    backupImage: nuhome60backup
    
  },
  nuhome75: {
    nuhomeSystem: "NuHome 75",
    moduleType: "Canadian 455W Mono Modules",
    moduleQty: 40,
    productWarranty: 12,
    performanceWarranty: 25,
    inverterType: "Victron Quatro 15kVA Inverter" ,
    invkWh: 15,
    mpptType: "Victron Smartsolar 250/200 MPPT",
    controller: "Venus GX Control",
    victronWarranty: 5,
    batteryType: "IG3N battery",
    batteryQty: 8,
    batterykWh: 37.6,
    usableEnergykWh: 30.24,
    solarArraykWp: 18,
    solarYieldDaily: 108,
    batteryWarranty: 10,
    inverterImage: inverterImage,
    batteryImage: nuhome75Battery,
    summaryImage: nuhome75,
    backupImage: nuhome75backup

  }
};

type nuhomeSystemTypes = 'nuhome10'|'nuhome15'|'nuhome20'|'nuhome30'|'nuhome40'|'nuhome45'|'nuhome60'|'nuhome75';

const generateSystemList = (systemName: nuhomeSystemTypes) => {
  switch (systemName) {
    case 'nuhome10':
      return [{system: 'nuhome10', ...nuHomeSystemComponents.nuhome10 }, {system: 'nuhome15', ...nuHomeSystemComponents.nuhome15}, {system: 'nuhome20', ...nuHomeSystemComponents.nuhome20}];
    case 'nuhome15':
      return [{system: 'nuhome10', ...nuHomeSystemComponents.nuhome10}, {system: 'nuhome15', ...nuHomeSystemComponents.nuhome15}, {system: 'nuhome20', ...nuHomeSystemComponents.nuhome20}];
    case 'nuhome20':
      return [{system: 'nuhome15', ...nuHomeSystemComponents.nuhome15}, {system: 'nuhome20', ...nuHomeSystemComponents.nuhome20}, {system: 'nuhome30', ...nuHomeSystemComponents.nuhome30}];
    case 'nuhome30':
      return [{system: 'nuhome20', ...nuHomeSystemComponents.nuhome20}, {system: 'nuhome30', ...nuHomeSystemComponents.nuhome30}, {system: 'nuhome40', ...nuHomeSystemComponents.nuhome40}];
    case 'nuhome40':
      return [{system: 'nuhome30', ...nuHomeSystemComponents.nuhome30}, {system: 'nuhome40', ...nuHomeSystemComponents.nuhome40}, {system: 'nuhome45', ...nuHomeSystemComponents.nuhome45}];
    case 'nuhome45':
      return [{system: 'nuhome40', ...nuHomeSystemComponents.nuhome40}, {system: 'nuhome45', ...nuHomeSystemComponents.nuhome45}, {system: 'nuhome60', ...nuHomeSystemComponents.nuhome60}];
    case 'nuhome60':
      return [{system: 'nuhome45', ...nuHomeSystemComponents.nuhome45}, {system: 'nuhome60', ...nuHomeSystemComponents.nuhome60}, {system: 'nuhome75', ...nuHomeSystemComponents.nuhome75}];
    case 'nuhome75':
      return [{system: 'nuhome45', ...nuHomeSystemComponents.nuhome45}, {system: 'nuhome60', ...nuHomeSystemComponents.nuhome60}, {system: 'nuhome75', ...nuHomeSystemComponents.nuhome75}];
  }
};

const calculateNuhomeSystem = (size: number):nuhomeSystemTypes => {
    if (size > 0 && size <= 13 ) return 'nuhome10';
    if (size > 13 && size <= 18) return 'nuhome15';
    if (size > 18 && size <= 23) return 'nuhome20';
    if (size > 23 && size <= 33) return 'nuhome30';
    if (size > 33 && size <= 43) return 'nuhome40';
    if (size > 43 && size <= 48) return 'nuhome45';
    if (size > 48 && size <= 63) return 'nuhome60';
    if (size > 63 && size <= 1000) return 'nuhome75';
    else return 'nuhome40';
};

const useProposal = () => {
   const { size, systemType } = useContext(ClientContext);
   const [loadingText, setLoadingText] = useState('Designing...');
   const [systemName, setSystemName] = useState<nuhomeSystemTypes>('nuhome40');
   const [systemList, setSystemList] = useState<any>([]);
   const [systemListWithCosts, setSystemListWithCosts] = useState<any>([]);
   const [costs, setCosts] = useState<DocumentData>({
     ac_box: null,
     coc: null,
     consumables: null,
     dc_box: null,
     installation: null,
     inverters: null,
     mounting: null,
     pv: null,
     storage: null,
     system_cabling: null
   });
   const [isBackup, setIsBackup] = useState(false);
   const [loading, setLoading] = useState(true);
   const [totals, setTotals] = useState({
     exVat: 0,
     vat: 0,
     incVat: 0
   });
   const [systemComponents, setSystemComponents] = useState({ 
    moduleType: "",
    moduleQty: 0,
    productWarranty: 0,
    performanceWarranty: 0,
    inverterType: "" ,
    mpptType: "",
    controller: "",
    victronWarranty: 0,
    batteryType: "",
    batteryQty: 0,
    batteryWarranty: 0
   });

   useEffect(() => {
     if (loadingText === 'Designing...') {
       const interval1 =  setInterval(() => {
        console.log("1");
        setLoadingText('Pricing...');
      }, 2000 );
      return () => clearInterval(interval1);
     }
   },[loadingText]);

   useEffect(() => {
     if (loadingText === 'Pricing...') {
       const interval2 = setInterval(() => {
        console.log("2");
        setLoadingText('Building Proposal...')
    }, 2000);
    return () => clearInterval(interval2);
     }
   },[loadingText])

   useEffect(() => {
     if (loadingText === 'Building Proposal...') {
       const interval3 = setInterval(() => {
         setLoading(false);
       }, 1400);
       return () => clearInterval(interval3);
     }
   },[loadingText])

   useEffect(() => {
     if (systemType === 'backup') setIsBackup(true);
   },[systemType]);

   useEffect(() => {
     if (size) setSystemName(calculateNuhomeSystem(size.systemSize));
   }, [size]);

   useEffect(() => {
     if (systemName) {
       setSystemList(generateSystemList(systemName));
     }
   }, [systemName]);

   useEffect(() => {
     const fetchSystemCosts = async() => {
       const collection = isBackup ? 'backup1' : 'system1';
       const docRef1 = doc(firestore, collection, systemList[0].system);
       const docSnap1 = await getDoc(docRef1);
       const docRef2 = doc(firestore, collection, systemList[1].system);
       const docSnap2 = await getDoc(docRef2);
       const docRef3 = doc(firestore, collection, systemList[2].system);
       const docSnap3 = await getDoc(docRef3);
       console.log(docSnap1.data(), docSnap2.data(), docSnap3.data());
       const updateSystemList = [];
       updateSystemList[0] = {
         ...systemList[0],
         ...docSnap1.data()
       };
       
       updateSystemList[1] = {
         ...systemList[1],
         ...docSnap2.data()
       };

       updateSystemList[2] = {
         ...systemList[2],
         ...docSnap3.data()
       };

       setSystemListWithCosts(updateSystemList);

       if (docSnap1.exists()) {
          console.log("doc1 fetched");
         
       } else {
         console.log("Something went wrong");
      }
     }
     if (systemList) {
      fetchSystemCosts().catch(error => console.log(error));
     }
   }, [systemList, isBackup]);

   return { systemName, costs, isBackup, loading, totals, systemComponents, systemList, loadingText, systemListWithCosts };
}

export default useProposal;
