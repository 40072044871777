
import { FC } from 'react';
import styled from 'styled-components';


interface IButton {
    onSubmit: () => void
  }



export const LightBlueButton: FC<any> = ({children, onSubmit, form}) => {
  return (
    <>
      <StyledLightBlueButton onSubmit={onSubmit} form={form}>{children}</StyledLightBlueButton>
    </>
  )
}

export const BlueButton: FC<any> = ({children}) => {
  return (
    <>
      <StyledBlueButton>{children}</StyledBlueButton>
    </>
  )
}


const StyledLightBlueButton = styled.button<IButton>`
  font-family: "Montserrat";
  font-size: 1rem;
  margin-left: 16px;
  color: #2696e8;
  background: rgba(53, 160, 238, 0.1);
  width: 154px;
  height: 48px;
  justify-content: center;
  border-radius: 4px;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 0;
  align-items: center;
`; 

  const StyledBlueButton = styled.button`
  font-family: "Montserrat";
  font-size: 1rem;
  margin-left: 16px;
  color: #fff;
  background-color: #35a0ee;
  box-shadow: 0 0 20px rgb(53, 160, 238 / 25% );
  width: 154px;
  height: 48px;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  cursor: pointer;
  border: none;
  outline: none;
  padding: 0;
`;
