import { createContext, useContext, PropsWithChildren, ReactNode, useState, useEffect } from "react";
import {doc, setDoc, collection, serverTimestamp } from "firebase/firestore";
import { firestore } from "../firebase";
import { UserContext } from "../Context/UserContext";


type houseSizeRange = 0|1|2|3|4|5;
type configurationRange = 0|1|3;
type roofTypeList = ''| 'tile' | 'ibr' | 'kliplock' | 'slate' | 'flatconcrete' | 'corrugated';
type proviceAllowedList = ''| 'gauteng';
type municipalityList = '' | 'ekurhuleni' | 'johannesburg' | 'tshwane' | 'emfuleni' | 'lesedi' | 'merafong' | 'midvaal' | 'mogale' | 'randwest' | 'sedibeng' | 'westrand';

interface IClientData {
  clientData: {
    name: string,
    phone: string,
    email: string,
    address: string,
  },
  size: {
    bill: number
    systemSize: number,
    houseSize:  houseSizeRange,
    kwhPeak: number,
    configuration: configurationRange,
    roofType: roofTypeList,
    province: proviceAllowedList,
    municipality: municipalityList,
    nuhomeSystem: string
  },
  systemType: string
  addClientDetails?: (data: IClientData['clientData']) => void,
  addSystemSize?: (siteInfo: any) => Promise<boolean>,
  addSystemType?: (systemtype: string) => Promise<boolean>,
  addDataToFirestore?: (system: string) => Promise<any>,
  progress?: {clientDetailComplete: boolean, systemSizeComplete: boolean, systemTypeComplete: boolean}
}



export const ClientContext = createContext<IClientData>({ 
    clientData: {
      name: '',
      phone: '',
      email: '',
      address: ''
    },
    size: {
      bill: 0,
      systemSize: 0,
      kwhPeak: 0,
      houseSize: 0,
      configuration: 0,
      roofType: '',
      province: '',
      municipality: '',
      nuhomeSystem: ''
    },
    systemType: ''
});

const ClientContextProvider = (props: ReactNode | PropsWithChildren<any>) => {
  const { user } = useContext(UserContext); 
  const [clientData, setClientData] = useState<IClientData['clientData']>({
    name: '',
    phone: '',
    email: '',
    address: ''
  });
  const [size, setSize] = useState<IClientData['size']>({
    bill: 0,
    systemSize: 0,
    kwhPeak: 0,
    houseSize: 0,
    configuration: 0,
    roofType: '',
    province: '',
    municipality: '',
    nuhomeSystem: ''
  });

  const [systemType, setSystemType] = useState<IClientData['systemType']>('');
  const [progress, setProgress] = useState({
      clientDetailComplete: false,
      systemSizeComplete: false,
      systemTypeComplete: false
    })

  const addClientDetails = (data: any) => {
    setClientData(data);
    setProgress({...progress, clientDetailComplete: true});
  };
  
  const addDataToFirestore = async (system: string) => {
    const newProposalRef = doc(collection(firestore, "proposals"));
    const result =  await setDoc(newProposalRef, {
        ...clientData,
        ...size,
        systemType: system,
        salesPerson: user.email,
        salesPersonId: user.uid,
        salesPersonCompany: user.company_id,
        createdAt: serverTimestamp()
        });
    return result;
  };

  useEffect(() => {
   console.log("systemType:", systemType, "size:", size, "clientData:", clientData);
  },[size, systemType, clientData]);

  const addSystemSize = async (siteInfo: any ): Promise<boolean> => {
    return new Promise((resolve) => {
    setSize({...siteInfo});
    setProgress({...progress, systemSizeComplete: true});
      resolve(true);
    })
  };

  const addSystemType = async (systemType: string): Promise<boolean> => {
    return new Promise((resolve) => {
      setSystemType(systemType);
      setProgress({...progress, systemTypeComplete: true});
      resolve(true);
    })
  };

  return (
    <ClientContext.Provider value={{clientData, size, systemType, addClientDetails, addSystemSize, addSystemType, addDataToFirestore, progress }} >
      {props.children}
    </ClientContext.Provider>
  )
};

export default ClientContextProvider
