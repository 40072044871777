import { useContext, useState, FormEvent } from "react";
import { useLocation, useNavigate } from "react-router-dom" 
import { UserContext } from "../../Context/UserContext";  
import validateLogin, { IErrors } from "./validateLogin";
import { logInWithEmailAndPassword, handleLoginWithEmailAndPasswordErrors, getUserRole } from "../../utils/firebaseUtils";
import { NUHOME, INSTALLER, TECHNICIAN, CUSTOMER, SALES } from '../../utils/constants';
  
interface ILocation { 
    state?: { 
        path?: string 
    }; 
};
 
const useLogin = () => { 
    const navigate = useNavigate(); 
    const { userLogin }    = useContext(UserContext); 
    const { state } = useLocation() as ILocation; 
    const [loading, setLoading] = useState(false); 
    const [values, setValues] = useState({ 
        email: '', 
        password: '' 
    }); 
 
    const [errors, setErrors] = useState({}); 
    const [message, setMessage] = useState(''); 
 
    const handleChange = (e: FormEvent<HTMLInputElement>) => { 
        const {name, value} = e.currentTarget; 
        setValues({ 
            ...values, 
            [name]: value 
        }); 
    }; 
 
    const handleSubmit = async (e:FormEvent<HTMLFormElement>) => { 
        e.preventDefault(); 
        setErrors({}); 
        const { errors } = validateLogin(values); 
        if (Object.keys(errors).length > 0) { 
            setErrors(errors); 
        } else { 
                // login in user using firebase, return token and set 
                setLoading(true); 
                const {email, password} = values;   
                const firebaseLogin = await logInWithEmailAndPassword(email, password);
                if (firebaseLogin.auth) {
                   const { uid, email } = firebaseLogin;
                   const userInfo = await getUserRole(uid);
                   console.log("LOGIN:",userInfo);
                   if (userInfo) {
                     const {role, company_id} = userInfo;
                     const login = await userLogin!({uid, email, role, company_id});
                     if (login === "SALES") navigate("/");

                   }

                }

                if (!firebaseLogin.auth) {
                    setMessage('Login failed');
                    setLoading(false);
                  };
        };
    };

    return { handleChange, handleSubmit, values, errors, message, loading };
};

export default useLogin;
