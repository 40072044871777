

import styled from "styled-components";
import { NUVO_BLUE } from "../utils/constants";

const TempSpinner = () => {
    return (
        <StyledSpinner viewBox="0 0 50 50">
            <circle
                className="path"
                cx="25"
                cy="25"
                r="20"
                fill="none"
                strokeWidth="4"
            />
        </StyledSpinner>
    )
}

export default TempSpinner;

const StyledSpinner = styled.svg`
  animation: rotate 2s linear infinite;
  vertical-align:top;
  width: 25px;
  height: 25px;

  & .path {
    stroke: ${NUVO_BLUE};
    stroke-linecap: round;
    animation: dash 1.5s ease-in-out infinite;
  }

  @keyframes rotate {
    100% {
      transform: rotate(360deg);
    }
  }
  @keyframes dash {
    0% {
      stroke-dasharray: 1, 150;
      stroke-dashoffset: 0;
    }
    50% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -35;
    }
    100% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -124;
    }
  }
`;
